import React, { FC } from 'react';
import PostCardSaveAction from 'components/PostCardSaveAction/PostCardSaveAction';
import { PostDataType } from 'data/types';
import PostCardLikeAndComment from 'components/PostCardLikeAndComment/PostCardLikeAndComment';
import CategoryBadgeList from 'components/CategoryBadgeList/CategoryBadgeList';
import PostTypeFeaturedIcon from 'components/PostTypeFeaturedIcon/PostTypeFeaturedIcon';
import PostFeaturedMedia from 'components/PostFeaturedMedia/PostFeaturedMedia';
import Link from 'components/Link';
import Image from 'components/Image';

export interface Card9Props {
  className?: string;
  ratio?: string;
  post: PostDataType;
  hoverClass?: string;
  hasActionButtons?: boolean;
}

const Card9: FC<Card9Props> = ({
  className = 'h-full',
  ratio = 'aspect-w-3 aspect-h-3 sm:aspect-h-4',
  post,
  hoverClass = '',
  hasActionButtons = true,
}) => {
  const {
    title,
    desc,
    href,
    featuredImage,
    categories,
    author,
    date,
    postType,
    tag,
  } = post;

  const renderMeta = () => {
    return (
      <div className="inline-flex items-center text-xs text-neutral-300">
        <div className="block">
          <h2 className="block text-base sm:text-lg font-semibold text-white ">
            <span className="line-clamp-2" title={title}>
              {title}
            </span>
          </h2>
          {desc && (
            <div className="block mt-2">
              <span className="text-neutral-300 text-sm">{desc}</span>
            </div>
          )}
          {author && (
            <Link href={author.href} className="flex mt-2.5 relative">
              <span className="block text-neutral-200 hover:text-white font-medium truncate">
                {author.displayName}
              </span>
              <span className="mx-[6px] font-medium">·</span>
              <span className="font-normal truncate">{date}</span>
            </Link>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-Card9 relative flex flex-col group rounded-3xl overflow-hidden z-0 ${hoverClass} ${className}`}
    >
      {hasActionButtons && (
        <div className="absolute inset-x-0 top-0 p-3 flex items-center justify-between transition-all opacity-0 z-[-1] group-hover:opacity-100 group-hover:z-10 duration-300">
          <PostCardLikeAndComment className="relative" />
          <PostCardSaveAction hidenReadingTime className="relative" />
        </div>
      )}
      <div className={`flex items-start relative w-full ${ratio}`}></div>
      {postType === 'audio' ? (
        <div className="absolute inset-0">
          <PostFeaturedMedia post={post} />
        </div>
      ) : (
        <Link href={href}>
          <Image
            fill
            alt=""
            className="object-cover w-full h-full rounded-3xl"
            src={featuredImage}
            sizes="(max-width: 600px) 480px, 500px"
          />
          <PostTypeFeaturedIcon
            className="absolute top-3 left-3 group-hover:hidden"
            postType={postType}
            wrapSize="w-7 h-7"
            iconSize="w-4 h-4"
          />
          <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span>
        </Link>
      )}
      <Link
        href={href}
        className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black opacity-50"
      ></Link>
      <div className="absolute bottom-0 inset-x-0 p-4 sm:p-5 flex flex-col flex-grow">
        <Link href={href} className="absolute inset-0"></Link>
        <div className="mb-3">
          <CategoryBadgeList categories={categories || [{ name: tag }]} />
        </div>
        {renderMeta()}
      </div>
    </div>
  );
};

export default Card9;
