import React, { FC } from 'react';
import Badge from 'components/UI/Badge/Badge';
import { CategoryDataType } from 'data/types';

export interface CategoryBadgeListProps {
  className?: string;
  itemClass?: string;
  categories: CategoryDataType[];
}

const CategoryBadgeList: FC<CategoryBadgeListProps> = ({
  className = 'flex flex-wrap space-x-2',
  itemClass,
  categories,
}) => {
  return (
    <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
      {categories &&
        categories.map((item, index) => (
          <div className="d-flex align-center justify-center" key={index}>
            {item?.parent && (
              <Badge
                className={itemClass}
                name={item.parent?.title}
                href={`/category/${item.parent?.id}`}
                color={item.color as any}
              />
            )}
            {item?.parent && <span className="mx-2">&gt;</span>}
            <Badge
              className={itemClass}
              name={item.title}
              href={`/category/${item.id}`}
              color={item.color as any}
            />
          </div>
        ))}
    </div>
  );
};

export default CategoryBadgeList;
