import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch as useDispatch } from 'store/store';
import axios from 'axios';

import {
  createQuestion,
  getQuestionUploadURL,
  updateQuestion,
  clearErrors as clearQuestionErrors,
  setLoading as setQuestionLoading,
} from 'store/question';
import {
  getCategories,
  getSubCategories,
  clearErrors as clearCategoryErrors,
  setLoading as setCategoryLoading,
} from 'store/category';

import Input from 'components/Input/Input';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import Select from 'components/Select/Select';
import Textarea from 'components/Textarea/Textarea';
import Label from 'components/Label/Label';
import Layout from './layout';
import { CategoryDataType } from 'data/types';

const DashboardSubmitPost = () => {
  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state.auth);
  const categories: CategoryDataType[] = useSelector(
    (state: RootState) => state.category.categories
  );
  const subcategories: CategoryDataType[] = useSelector(
    (state: RootState) => state.category.subcategories
  );
  const { isLoading } = useSelector((state: RootState) => state.question);

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string[]>([]);

  const [selectedVideo, setSelectedVideo] = useState<File>();
  const [videoError, setVideoError] = useState<{ message: string }[]>([]);
  const [showRecordVideoDisplay, setShowRecordVideoDisplay] = useState(false);

  const handleCategoryChange = (event: { target: HTMLSelectElement }) => {
    const value = event.target.value;
    setSelectedCategories([value]);
    setSelectedSubCategory([]);
  };

  const handleSubCategoryChange = (event: { target: HTMLSelectElement }) => {
    const value = event.target.value;
    setSelectedSubCategory([value]);
    console.log('selectedSubCategory', selectedSubCategory);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getCategories());
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (selectedCategories[0]) {
        await dispatch(getSubCategories({ id: selectedCategories[0] }));
      }
    };

    fetchSubCategories();
  }, [dispatch, selectedCategories]);

  useEffect(() => {
    return () => {
      dispatch(clearQuestionErrors());
      dispatch(clearCategoryErrors());
      dispatch(setQuestionLoading(false));
      dispatch(setCategoryLoading(false));
    };
  }, [dispatch]);

  const handleVideoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let duration;
    const video = document.createElement('video');
    const target = e.target;
    if (target && target.files && target.files[0]) {
      video.src = URL.createObjectURL(target.files[0]);
      video.onloadedmetadata = function () {
        URL.revokeObjectURL(video.src);
        duration = video.duration;

        if (duration > 75) {
          setVideoError([{ message: 'Video should be under 60s' }]);
          return;
        } else {
          setVideoError([]);
        }
      };

      setSelectedVideo(target.files[0]);
    }
    return;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedVideo) {
      const res = await dispatch(
        createQuestion({
          title,
          body,
          categories:
            selectedSubCategory?.length > 0
              ? selectedSubCategory
              : selectedCategories,
          status: 'approved',
        })
      );
      if (res) {
        navigate(`/`);
      }
      return;
    }

    if (videoError.length > 0) {
      return;
    }

    dispatch(setQuestionLoading(true));
    const response = await dispatch(
      getQuestionUploadURL({
        title,
        body,
        filename: selectedVideo.name,
        fileType: selectedVideo.type,
      })
    );

    const { url: uploadURL, questionId } = response;

    try {
      console.log(`Uploading file to ${uploadURL}`);
      const response = await axios.put(uploadURL, selectedVideo, {
        headers: {
          'Content-Type': selectedVideo.type,
        },
      });

      console.log(`File uploaded successfully`);

      if (response.status === 200) {
        console.log(`Updating question in the database`);
        const res = await dispatch(
          updateQuestion({
            id: questionId,
            title,
            body,
            categories:
              selectedSubCategory?.length > 0
                ? selectedSubCategory
                : selectedCategories,
            video: selectedVideo.name,
          })
        );
        if (res) {
          console.log(`Question updated successfully`);
          navigate(`/`);
        }
      } else {
        // delete the question from the database
      }
    } catch (err) {
      // delete the question from the database
      console.log(`Error while uploading file ${err}`);
    }
    dispatch(setQuestionLoading(false));
  };

  return (
    <Layout>
      <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
        <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
          <label className="block md:col-span-2">
            <Label>Title *</Label>
            <Input
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              name="title"
              className="mt-1"
              required
            />
          </label>
          <label className="block md:col-span-2">
            <Label>Description *</Label>
            <Textarea
              className="mt-1"
              rows={4}
              onChange={(e) => setBody(e.target.value)}
              name="description"
              required
            />
            <p className="mt-1 text-sm text-neutral-500">
              Brief description for your question
            </p>
          </label>

          <div className="flex flex-col">
            <label className="block mb-2">
              <Label>Category *</Label>
              <Select
                className="mt-1"
                value={selectedCategories}
                onChange={handleCategoryChange}
                name="categories"
                defaultValue={''}
                required
              >
                <option key={`0`} value={``}>
                  Select a category
                </option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.title}
                  </option>
                ))}
              </Select>
            </label>

            <label className="block mt-4 mb-2">
              <Label
                className={
                  selectedCategories.length === 0 || subcategories.length === 0
                    ? 'font-normal text-gray-300 dark:text-gray-500'
                    : ''
                }
              >
                Sub Category *
              </Label>
              <Select
                className="mt-1"
                value={selectedSubCategory}
                onChange={handleSubCategoryChange}
                name="subcategory"
                disabled={
                  selectedCategories.length === 0 || subcategories.length === 0
                }
                required
              >
                <option key={`0`} value={``}>
                  {selectedCategories.length === 0
                    ? 'Choose a category'
                    : subcategories.length === 0
                    ? 'No sub categories found'
                    : 'Select a sub category'}
                </option>
                {subcategories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.title}
                  </option>
                ))}
              </Select>
            </label>
          </div>

          <div className="flex flex-col gap-1">
            <Label>Upload Video</Label>
            <div className="flex justify-center items-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md h-full">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m24-4l4-4m0 0l-4-4m4 4H16"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-500 dark:text-primary-300"
                  >
                    <span>Upload a video file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      accept="video/*"
                      onChange={handleVideoChange}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-neutral-500 dark:text-neutral-400">
                  MP4, AVI up to 60s
                </p>
                {videoError.length > 0 &&
                  videoError.map((error, index) => (
                    <span key={index} className="error text-red-600">
                      {error.message}
                    </span>
                  ))}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 md:col-span-2 mb-2">
            <ButtonPrimary type="submit" loading={isLoading}>
              {auth.currentUser?._id ? 'Publish now' : 'Sign in to publish'}
            </ButtonPrimary>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default DashboardSubmitPost;
