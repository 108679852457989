import QuestionCardSmall from 'components/UI/cards/QuestionCardSmall';
import WidgetHeading1 from 'components/WidgetHeading1/WidgetHeading1';
import { QuestionDataType } from 'data/types';
import { FC } from 'react';

export interface WidgetQuestionsProps {
  className?: string;
  questions?: QuestionDataType[];
}

const WidgetQuestions: FC<WidgetQuestionsProps> = ({
  className = 'bg-neutral-100 dark:bg-neutral-800',
  questions,
}) => {
  return (
    <div
      className={`nc-WidgetQuestions rounded-3xl overflow-hidden ${className}`}
    >
      <WidgetHeading1
        title="🎯 Popular Questions"
        // viewAll={{ label: 'View all', href: '/search?searchQuery=' }}
      />
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {questions?.map((question) => (
          <QuestionCardSmall
            className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
            key={question.id}
            question={question}
          />
        ))}
      </div>
    </div>
  );
};

export default WidgetQuestions;
