import Input from 'components/Input/Input';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import ButtonCircle from 'components/Button/ButtonCircle';
import { ArrowSmallRightIcon } from '@heroicons/react/24/outline';

const SearchForm = ({
  value,
  onChange,
  onSubmit,
  handleKeyDown,
}: {
  handleKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
  onSubmit: Function;
  value: string;
  onChange: Function;
}) => {
  return (
    <div className='relative group flex-1 max-w-[800px]'>
      <span className='absolute top-1/2 -translate-y-1/2 left-3 text-neutral-500 dark:text-neutral-400'>
        <MagnifyingGlassIcon className='h-4 w-4' />
      </span>
      <Input
        type='text'
        placeholder='Start to type in your question here...'
        className='!px-8 md:px-10 w-full group-hover:border-slate-300 dark:group-hover:border-slate-400 dark:placeholder:text-neutral-400 z-100'
        sizeClass='h-[42px] pl-4 py-3'
        autoFocus
        value={value}
        onChange={(e) => onChange(e)}
        onSubmit={() => onSubmit()}
        onKeyDown={handleKeyDown}
      />
      <ButtonCircle
        className='absolute right-2.5 top-1/2 transform -translate-y-1/2'
        size=' w-8 h-8'
        type='submit'
      >
        <ArrowSmallRightIcon className='w-6 h-6' onClick={() => onSubmit()} />
      </ButtonCircle>
    </div>
  );
};

export default SearchForm;
