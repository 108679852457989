import ForIndividuals from 'app/(singles)/ForIndividuals';
import Layout from '../layout';
import SingleHeader from 'components/UI/Single/SingleHeader';

const IndividualsPageAbout = () => {
  return (
    <Layout content={<ForIndividuals />}>
      <header className="relative py-10 z-10 md:py-14 lg:py-20 bg-neutral-900 dark:bg-black">
        {/* SINGLE HEADER */}
        <div className="dark container relative z-10">
          <div className="max-w-screen-lg">
            <SingleHeader
              title="Are you looking for answers from experts to your most important questions?"
              tagName="For Individuals"
              href="/individuals"
              date="08/13/2024"
              readingTime="2 min read"
            />
          </div>
        </div>
      </header>
    </Layout>
  );
};

export default IndividualsPageAbout;
