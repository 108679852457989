import React, { FC } from 'react';
import ButtonCircle from 'components/Button/ButtonCircle';
import rightImg from 'images/SVG-subcribe2.png';
import Badge from 'components/UI/Badge/Badge';
import Input from 'components/Input/Input';
import Image from 'components/Image';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

import { useSelector } from 'react-redux';
import { RootState, useAppDispatch as useDispatch } from 'store/store';
import { setEmail, subscribeEmail } from 'store/subscription';

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = '' }) => {
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.subscription.email);
  const subscriptionMessage = useSelector(
    (state: RootState) => state.subscription.subscriptionMessage
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await dispatch(subscribeEmail({ email }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmail(event.target.value));
  };

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center ${className}`}
    >
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Follow our journey</h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          Sign up to follow updates as we build Orakly and hear about Early
          Adopter offers…
        </span>
        <ul className="space-y-5 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Follow our story
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get discounted advertiser rates
            </span>
          </li>
        </ul>
        <form className="mt-10 relative max-w-sm" onSubmit={handleSubmit}>
          <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            value={email}
            onChange={handleChange}
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1 dark:bg-neutral-300 dark:text-black"
          >
            <ArrowRightIcon className="w-5 h-5 " />
          </ButtonCircle>
        </form>

        {subscriptionMessage !== '' ? (
          <div className="p-2 mt-2">
            <p
              className={`${
                subscriptionMessage !== 'Successfully Subscribed Email!'
                  ? 'text-red-500'
                  : 'text-green-500'
              }`}
            >
              {subscriptionMessage}
            </p>
          </div>
        ) : null}
      </div>
      <div className="flex-grow">
        <Image
          alt="subsc"
          sizes="(max-width: 768px) 100vw, 50vw"
          src={rightImg}
        />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
