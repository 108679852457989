import React, { FC, useState, useEffect } from 'react';
import { QuestionDataType } from 'data/types';
import isSafariBrowser from 'utils/isSafariBrowser';
import ReactPlayer from 'react-player/lazy';
import NcPlayIcon from 'components/NcPlayIcon/NcPlayIcon';

import Image from 'components/Image';

export interface QuestionContentDataProps {
  question: QuestionDataType | null;
}
const QuestionContentData: FC<QuestionContentDataProps> = ({ question }) => {
  const [isPlay, setIsPlay] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const renderMainVideo = () => {
    return (
      <div>
        {isSafariBrowser() && !isPlay && (
          <div
            className="absolute inset-0 z-10 cursor-pointer "
            onClick={() => setIsPlay(true)}
          >
            <Image
              src={`${process.env.REACT_APP_AWS_THUMBNAIL_URL}/${question?.thumbnail}`}
              className="object-cover"
              sizes="(max-width: 1024px) 100vw, 50vw"
              alt="single"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <NcPlayIcon />
            </div>
          </div>
        )}
        {isRendered && (
          <ReactPlayer
            url={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/${question?.video}`}
            className="absolute inset-0"
            playing={isSafariBrowser() ? isPlay : true}
            width="100%"
            height="100%"
            controls
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            onContextMenu={(
              e: React.MouseEvent<HTMLVideoElement, MouseEvent>
            ) => e.preventDefault()}
            light={
              isSafariBrowser()
                ? false
                : `${process.env.REACT_APP_AWS_THUMBNAIL_URL}/${question?.thumbnail}`
            }
            playIcon={<NcPlayIcon />}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <p>{question?.body}</p>
      {question?.status === 'approved' ? (
        <>
          {' '}
          {question?.video && (
            <div className="aspect-w-16 aspect-h-16 sm:aspect-h-9 border-4 border-neutral-300 dark:border-neutral-800 shadow-2xl bg-neutral-800 rounded-3xl overflow-hidden z-0">
              {renderMainVideo()}
            </div>
          )}
        </>
      ) : question?.status === 'rejected' ? (
        <div className="text-red-500 italic">
          <p>This video cannot be shown due to policy.</p>
        </div>
      ) : (
        <div className="text-indigo-500">
          <p>This video is pending approval.</p>
        </div>
      )}

      {question?.transcript &&
        question?.transcript.map((item, index) => (
          <blockquote
            key={index}
            className="my-8 text-neutral-500 dark:text-neutral-400"
          >
            "{item.transcript}"
          </blockquote>
        ))}
    </>
  );
};

export default QuestionContentData;
