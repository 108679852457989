import React, { FC } from 'react';
import { UserDataType } from 'data/types';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import Avatar from 'components/Avatar/Avatar';
import NcImage from 'components/NcImage/NcImage';
import Link from 'components/Link';
import moment from 'moment';
import Badge from 'components/UI/Badge/Badge';

export interface CardAuthorBox2Props {
  className?: string;
  author: UserDataType;
  index: number;
  type: 'newestAuthors' | 'topAuthors';
}

const CardAuthorBox3: FC<CardAuthorBox2Props> = ({
  className = '',
  author,
  index,
  type,
}) => {
  const {
    firstName,
    lastName,
    id,
    avatarUrl,
    subCategoryExpertise,
    score,
    createdAt,
  } = author;
  return (
    <Link
      href={`/profile/${id}`}
      className={`nc-CardAuthorBox2 flex flex-col overflow-hidden bg-white dark:bg-neutral-800 rounded-3xl ${className}`}
    >
      <div className="relative flex-shrink-0 ">
        <div>
          <NcImage
            alt="BG Image"
            containerClassName="flex aspect-w-7 aspect-h-5 w-full h-0"
            src={bgImages[index]}
            fill
            sizes="(max-width: 600px) 480px, 33vw"
          />
        </div>
        <div className="absolute top-3 inset-x-3 flex">
          <div className=" py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
            {type === 'newestAuthors'
              ? moment(createdAt).format('MMM YY')
              : score}{' '}
            <ArrowRightIcon className="w-5 h-5 text-yellow-600 ml-3" />
          </div>
        </div>
      </div>

      <div className="-mt-8 m-8 text-center">
        <Avatar
          containerClassName="ring-2 ring-white"
          sizeClass="w-16 h-16 text-2xl"
          radius="rounded-full"
          imgUrl={avatarUrl}
          userName={`${firstName} ${lastName}`}
        />
        <div className="mt-3">
          <h2 className={`text-base font-medium`}>
            <span className="line-clamp-1 capitalize">{`${firstName} ${lastName}`}</span>
          </h2>
          <span
            className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
          >
            <Badge
              name={subCategoryExpertise || 'Category Expertise'}
              color={'purple'}
            />
          </span>
        </div>
      </div>
    </Link>
  );
};

export default CardAuthorBox3;

const bgImages = [
  'https://images.pexels.com/photos/912410/pexels-photo-912410.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/7354542/pexels-photo-7354542.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/3651577/pexels-photo-3651577.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/3330118/pexels-photo-3330118.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/4066850/pexels-photo-4066850.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/931887/pexels-photo-931887.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/7175377/pexels-photo-7175377.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/7663205/pexels-photo-7663205.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/973505/pexels-photo-973505.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
];
