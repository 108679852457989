import { FC } from 'react';
import {
  QuestionMarkCircleIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline';

export interface CardAuthor2Props {
  className?: string;
  title: string;
  count: number;
  isQuestion?: boolean;
}

const ActivityCard: FC<CardAuthor2Props> = ({
  className,
  title,
  count,
  isQuestion = true,
}) => {
  return (
    <div
      className={`nc-CardActivities inline-flex space-x-2.5 items-center bg-neutral-50 px-4 py-2 rounded-xl text-neutral-600 hover:text-neutral-800 hover:bg-neutral-200 dark:bg-neutral-800 dark:text-neutral-300 dark:hover:text-neutral-50 ${className}`}
    >
      {isQuestion ? (
        <QuestionMarkCircleIcon className='w-6 h-6' />
      ) : (
        <ChatBubbleLeftRightIcon className='w-6 h-6' />
      )}
      <div className='flex flex-col justify-center'>
        <h2 className={`text-sm font-medium`}>
          {title}: {count}
        </h2>
      </div>
    </div>
  );
};

export default ActivityCard;
