import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch as useDispatch } from 'store/store';
import { getQuestion, clearErrors, clearQuestion } from 'store/question';

// import NcImage from 'components/NcImage/NcImage';
import QuestionHeader from 'app/(question)/detail/QuestionHeader';
import Layout from './layout';
import {
  CurrentUserType,
  QuestionDataType,
  CategoryDataType,
} from 'data/types';

const PageSingle = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getQuestion(id));
    };

    fetchData();

    return () => {
      dispatch(clearQuestion());
      dispatch(clearErrors());
    };
  }, [dispatch, id]);

  const questionSlice = useSelector((state: RootState) => state.question);

  const question: QuestionDataType = questionSlice.question!;

  return (
    <Layout question={question}>
      <div className={`nc-PageSingle pt-8 lg:pt-16`}>
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            {question && (
              <QuestionHeader
                categories={question['categories'] as CategoryDataType[]}
                title={question['title'] as string}
                user={question['user'] as CurrentUserType}
                createdAt={question['createdAt'] as Date}
                hiddenDesc={true}
                question={question}
              />
            )}
          </div>
        </header>

        {/* FEATURED IMAGE */}
        {/* <NcImage
          alt="single"
          containerClassName="container my-10 sm:my-12"
          className="w-full rounded-xl"
          src="https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg"
          width={1260}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        /> */}
      </div>
    </Layout>
  );
};

export default PageSingle;
