import { FC } from 'react';
import NcImage from 'components/NcImage/NcImage';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import HeroSectionCard from 'components/UI/cards/HeroSectionCard';
import { PostDataType } from 'types/types';

export interface SectionHero {
  posts: PostDataType[];
  className?: string;
  featuredImage: string;
  title: string;
  desc: string;
  href: string;
  btnText: string;
}

const SectionHero: FC<SectionHero> = ({
  posts,
  className = '',
  title,
  desc,
  featuredImage,
  href,
  btnText,
}) => {
  const renderMain = () => {
    return (
      <div className="aspect-h-12 aspect-w-14 xs:aspect-w-16 sm:aspect-h-8 md:aspect-w-14 lg:aspect-w-16 lg:aspect-h-7 xl:aspect-h-6">
        <NcImage
          alt=""
          containerClassName="absolute inset-0 rounded-[40px] overflow-hidden z-0"
          src={featuredImage}
          fill
          sizes="(max-width: 1024px) 100vw, 1280px"
        />
        <span className="absolute inset-0 rounded-[40px] bg-gradient-to-r from-[#000]"></span>
        <div className="absolute inset-0 p-5 sm:p-8 md:p-12 lg:p-16 2xl:p-28 2xl:pt-20">
          <div className="max-w-2xl">
            <h2 className="text-xl sm:text-3xl lg:text-4xl font-semibold text-white">
              <span className="line-clamp-2">{title}</span>
            </h2>
            <span className="block text-sm sm:text-base text-neutral-300 mt-3 sm:mt-5">
              <span className="line-clamp-2">{desc}</span>
            </span>
            <div className="mt-5 sm:mt-8">
              {!!title && <ButtonPrimary href={href}>{btnText}</ButtonPrimary>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSubPosts = () => {
    const subPosts = posts.filter((_, i) => i >= 0 && i < 4);
    return (
      <div className="md:px-6 xl:px-20 2xl:px-28 grid sm:grid-cols-2 md:grid-cols-3 gap-4 lg:gap-8 transform mt-6 md:-mt-20">
        {subPosts.map((post) => (
          <HeroSectionCard
            className="bg-white dark:bg-neutral-800 shadow-2xl rounded-3xl"
            key={post.title}
            post={post}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={`nc-SectionHero3 ${className}`}>
      {renderMain()}
      {posts.length > 1 && renderSubPosts()}
    </div>
  );
};

export default SectionHero;
