import WidgetHeading1 from 'components/WidgetHeading1/WidgetHeading1';
import { TrendingCategoriesDataType } from 'data/types';
import CardCategory from '../cards/CardCategory';
import { FC } from 'react';

export interface WidgetCategoriesProps {
  className?: string;
  categories?: TrendingCategoriesDataType[];
}

const WidgetCategories: FC<WidgetCategoriesProps> = ({
  className = 'bg-neutral-100 dark:bg-neutral-800',
  categories,
}) => {
  return (
    <div
      className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
    >
      <WidgetHeading1 title="✨ Trending Categories" />
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
          {categories?.map((category) => (
            <CardCategory
              className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={category.id}
              category={category}
              size="normal"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WidgetCategories;
