import { FC } from 'react';
import { QuestionDataType } from 'data/types';
import Link from 'components/Link';
import Image from 'components/Image';
import AuthorCardMeta from '../CardMeta/AuthorCardMeta';

export interface QuestionCardSmallProps {
  className?: string;
  question: QuestionDataType;
}

const QuestionCardSmall: FC<QuestionCardSmallProps> = ({
  className = 'h-full',
  question,
}) => {
  const { title, createdAt, thumbnail, user, id } = question;

  return (
    <div
      className={`nc-QuestionCardSmall relative flex flex-row justify-between items-center ${className}`}
    >
      <Link
        href={`/questions/${id}`}
        className="absolute inset-0"
        title={title}
      ></Link>
      <div className="relative space-y-2">
        <AuthorCardMeta date={createdAt} author={user} />
        <h2 className="nc-card-title block text-sm sm:text-base font-medium sm:font-semibold text-neutral-900 dark:text-neutral-100">
          <Link
            href={`/questions/${id}`}
            className="line-clamp-2"
            title={title}
          >
            {title}
          </Link>
        </h2>
      </div>

      <Link
        href={`/questions/${id}`}
        title={title}
        className={`block w-20 flex-shrink-0 relative rounded-lg overflow-hidden z-0 ml-4 group`}
      >
        <div className={`w-full h-0 aspect-w-1 aspect-h-1`}>
          <Image
            alt={question.title}
            fill
            sizes="100px"
            className="object-cover w-full h-full group-hover:scale-110 transform transition-transform duration-300"
            src={
              question?.status === 'approved'
                ? question?.thumbnail
                  ? `${process.env.REACT_APP_AWS_THUMBNAIL_URL}/${thumbnail}`
                  : `/background-bg.jpg`
                : `/background-bg.jpg`
            }
          />
        </div>
      </Link>
    </div>
  );
};

export default QuestionCardSmall;
