import { configureStore } from '@reduxjs/toolkit';

import { useDispatch } from 'react-redux';

import authReducer from './auth';
import authorReducer from './author';
import questionReducer from './question';
import categoryReducer from './category';
import subscriptionReducer from './subscription'

const store = configureStore({
  reducer: {
    auth: authReducer,
    author: authorReducer,
    question: questionReducer,
    category: categoryReducer,
    subscription: subscriptionReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;
