import { createSlice } from '@reduxjs/toolkit';
import { AnswerDataType, UserDataType } from 'data/types';

import { AppDispatch as Dispatch } from './store';
import axiosInstance from '../services/axiosInstance';
import catchErrors from '../services/catchErrors';

const initialState = {
  isLoading: false,
  errors: [],
  newestAuthors: [] as UserDataType[],
  topAuthors: [] as UserDataType[],
  userAnswers: [] as AnswerDataType[]
};

export const authorSlice = createSlice({
  name: 'author',
  initialState,
  reducers: {
    setNewestAuthors: (state, action) => {
      state.newestAuthors = action.payload;
    },
    setTopAuthors: (state, action) => {
      state.topAuthors = action.payload;
    },
    setUserAnswers: (state, action) => {
      state.userAnswers = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clearErrors: (state) => {
      state.errors = [];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});


export const {
  setNewestAuthors,
  setTopAuthors,
  setUserAnswers,
  setErrors,
  clearErrors,
  setLoading,
} = authorSlice.actions;


// --------------------------------------------------------

export const getNewestAuthors = () => async (dispatch: Dispatch) => {
  console.log('getNewestAuthors');
  try {
    dispatch(setLoading(true));
    const response = await axiosInstance({
      url: `/api/author/newest-authors`,
      method: 'GET',
    });

    dispatch(setLoading(false));
    dispatch(setNewestAuthors(response.data.newestAuthors));
    return true;
  } catch (err) {
    console.log(`Error while getting newest authors: ${err}`);
    const errs = catchErrors(err);
    dispatch(setErrors(errs));
    dispatch(setLoading(false));
    return false;
  }
};

// --------------------------------------------------------


export const getTopAuthors = (id: string) => async (dispatch: Dispatch) => {
  console.log('getTopAuthors');
  try {
    dispatch(setLoading(true));
    const response = await axiosInstance({
      url: `/api/author/top-authors/${id}`,
      method: 'GET',
    });

    dispatch(setLoading(false));
    dispatch(setTopAuthors(response.data.topAuthors));
    return true;
  } catch (err) {
    console.log(`Error while getting top authors: ${err}`);
    const errs = catchErrors(err);
    dispatch(setErrors(errs));
    dispatch(setLoading(false));
    return false;
  }
};

// --------------------------------------------------------

export const getUserAnswers = ({ id }: { id: string }) => async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/answers/user/${id}`,
        method: 'GET',
      });

      dispatch(setUserAnswers(response.data.answers));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting answers: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export default authorSlice.reducer;
