import { FC } from 'react';
import MainNav from './MainNav';

export interface MainHeaderProps {}

const MainHeader: FC<MainHeaderProps> = () => {
  return (
    <div className='nc-Header sticky top-0 w-full z-40'>
      <MainNav />
    </div>
  );
};

export default MainHeader;
