import { FC } from 'react';
import NcImage from 'components/NcImage/NcImage';
import { TrendingCategoriesDataType } from 'data/types';
import Link from 'components/Link';
import trendingCategoriesPlaceholder from '../../../assets/images/trending-topics-placeholder.jpg';

export interface CardCategoryProps {
  className?: string;
  category: TrendingCategoriesDataType;
  size?: 'large' | 'normal';
  handleClick?: () => void;
}

const CardCategory: FC<CardCategoryProps> = ({
  className = '',
  size = 'normal',
  category,
  handleClick,
}) => {
  const { id: categoryId, count, title, thumbnail } = category;
  return (
    <div onClick={handleClick}>
      <Link
        href={`/category/${categoryId}`}
        className={`nc-CardCategory1 flex items-center ${className}`}
      >
        <NcImage
          alt=""
          containerClassName={`relative flex-shrink-0 ${
            size === 'large' ? 'w-20 h-20' : 'w-12 h-12'
          } rounded-lg mr-4 overflow-hidden`}
          src={thumbnail || trendingCategoriesPlaceholder}
          fill
          className="object-cover"
          sizes="80px"
        />
        <div>
          <h2
            className={`${
              size === 'large' ? 'text-lg' : 'text-base'
            } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
          >
            {title}
          </h2>
          <span
            className={`${
              size === 'large' ? 'text-sm' : 'text-xs'
            } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
          >
            {count} Articles
          </span>
        </div>
      </Link>
    </div>
  );
};

export default CardCategory;
