export const languages = [
    { id: 1, name: 'English' },
    { id: 2, name: 'Chinese' },
    { id: 3, name: 'Spanish' },
    { id: 4, name: 'Arabic' },
    { id: 5, name: 'Hindi' },
    { id: 6, name: 'Bengali' },
    { id: 7, name: 'Portuguese' },
    { id: 8, name: 'Russian' },
    { id: 9, name: 'Japanese' }
]