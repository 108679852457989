import React, { FC } from 'react';
import { QuestionDataType } from 'data/types';
import Link from 'components/Link';
import Image from 'components/Image';

export interface PostFeaturedMediaProps {
  className?: string;
  question: QuestionDataType;
  isHover?: boolean;
}

const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({
  className = 'w-full h-full',
  question,
  isHover = false,
}) => {
  const { thumbnail, id } = question;

  // const isPostMedia = () => postType === 'video' || postType === 'audio';

  // const renderGallerySlider = () => {
  //   if (!galleryImgs) return null;
  //   return (
  //     <GallerySlider
  //       href={href}
  //       galleryImgs={galleryImgs}
  //       className="absolute inset-0 z-10"
  //       galleryClass="absolute inset-0"
  //       ratioClass="absolute inset-0"
  //     />
  //   );
  // };

  // const renderContent = () => {
  //   // GALLERY
  //   if (postType === 'gallery') {
  //     return renderGallerySlider();
  //   }

  //   // VIDEO
  //   if (postType === 'video' && !!videoUrl && isHover) {
  //     return <MediaVideo isHover videoUrl={videoUrl} />;
  //   }

  //   // AUDIO
  //   if (postType === 'audio' && !!audioUrl) {
  //     return <MediaAudio post={post} />;
  //   }

  //   // ICON
  //   return isPostMedia() ? (
  //     <span className="absolute inset-0 flex items-center justify-center ">
  //       <PostTypeFeaturedIcon
  //         className="hover:scale-105 transform cursor-pointer transition-transform"
  //         postType={postType}
  //       />
  //     </span>
  //   ) : null;
  // };

  return (
    <div className={`nc-PostFeaturedMedia relative ${className}`}>
      <Image
        alt={question.title}
        fill
        className="object-cover"
        src={
          question?.status === 'approved'
            ? question?.thumbnail
              ? `${process.env.REACT_APP_AWS_THUMBNAIL_URL}/${thumbnail}`
              : `/background-bg.jpg`
            : `/background-bg.jpg`
        }
        sizes="(max-width: 600px) 480px, 800px"
      />
      <Link
        href={`/questions/${question.id}`}
        className={`block absolute inset-0 ${'bg-black/20 transition-opacity opacity-0 group-hover:opacity-100'}`}
      />
    </div>
  );
};

export default PostFeaturedMedia;
