import Image from 'components/Image';
import featured_img from '../../images/orakly-profile.png';

const IndividualsAboutContent = () => {
  return (
    <>
      <h2>For Individuals - Getting Answers</h2>
      <p>
        Are you looking for objective, trusted and validated answers to your
        most important questions? Great, welcome to Orakly, the trusted,
        Q&A-based expertise marketplace!
      </p>
      <blockquote>
        <p>Orakly: Driving the trust in expert advice, one video at a time!</p>
      </blockquote>
      <p>
        Orakly is a video-based expertise marketplace, where those wanting to be
        seen as experts are encouraged to provide trusted answers to your
        questions, with video.
      </p>
      <p>For those of you with questions, the process is really simple:</p>
      <ol>
        <li>
          Create an account - see ‘
          <a href="/signup" target="_blank" rel="noopener noreferrer">
            Get Started
          </a>
          ’
        </li>
        <li>
          Ask a question - with or without a video to help those answering it to
          know more about what you’re looking for
          <ol type="a">
            <li>Ask a question to the whole site for ANYONE to answer</li>
            <li>Ask a question directly to a business</li>
          </ol>
        </li>
        <li>Share it with your network - we will, we send your</li>
        <li>
          Check back to see if your question has been answered and feed back so
          we can reward the user who answered it
        </li>
      </ol>
      <h2>Who To Trust?</h2>
      <p>
        Knowing who to trust on Orakly is established by looking for our
        OraklyScore™ which is based on the author’s history on Orakly; how much
        they have contributed to their sector and the feedback they get.
      </p>
      <p>
        Look for ‘Category Captains’ on the category page, those who have built
        the best profiles, and look for the author’s OraklyScore<sup>TM</sup> on
        their profile too:
      </p>
      <Image
        src={featured_img}
        alt="nc blog"
        sizes="(max-width: 1024px) 100vw, 1240px"
        className="rounded-2xl"
        width={1635}
        height={774}
      />
    </>
  );
};

export default IndividualsAboutContent;
