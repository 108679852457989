import { ReactNode } from 'react';
import { auth } from '../../../services/firebase';
import { setCurrentUser } from 'store/auth';
import { useAppDispatch as useDispatch } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

import { Route } from 'routers/types';
import Link from 'components/Link';
import Heading2 from 'components/Heading/Heading2';
import usePathname from 'hooks/usePathname';

const Layout = ({ id, children }: { id: string; children: ReactNode }) => {
  const subPages: { href: Route; pageName: string; emoij: string }[] = [
    // {
    //   href: '/profile/' + id + '/dashboard',
    //   emoij: '⏳',
    //   pageName: 'Dash board',
    // },
    // {
    //   href: '/profile/' + id + '/posts',

    //   emoij: '📕',
    //   pageName: 'Posts',
    // },
    {
      href: '/profile/' + id + '',

      emoij: '🛠',
      pageName: 'Edit profile',
    },
    // {
    //   href: '/profile/' + id + '/subscription',
    //   emoij: '📃',
    //   pageName: 'Subscription',
    // },
    // {
    //   href: '/profile/' + id + '/billing-address',

    //   emoij: '✈',
    //   pageName: 'Billing address',
    // },
    // {
    //   href: '/profile/' + id + '/submit-post',

    //   emoij: '✍',
    //   pageName: 'Submit post',
    // },
  ];
  const pathname = usePathname();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    await signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log('Signed Out!');
        dispatch(setCurrentUser(null));
        navigate('/');
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  return (
    <div className={`nc-PageDashboard`}>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-24">
        <Heading2 emoji="">Dashboard</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          View your dashboard, manage your posts, subscription and edit and
          profile.
        </span>
      </header>

      <div className="flex flex-col space-y-8 xl:space-y-0 xl:flex-row">
        {/* SIDEBAR */}

        <div className="flex-shrink-0 max-w-xl xl:w-80 xl:pr-8">
          <ul className="text-base space-y-1 text-neutral-700 dark:text-neutral-400">
            {subPages.map(({ href, pageName, emoij }, index) => {
              return (
                <li key={index}>
                  <Link
                    className={`px-6 py-3 font-medium rounded-full flex items-center ${
                      pathname === href
                        ? 'bg-neutral-100 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100'
                        : 'hover:text-neutral-800 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-100'
                    }`}
                    href={href}
                  >
                    <span className="w-8 mr-2 text-lg">{emoij}</span>
                    <span> {pageName}</span>
                  </Link>
                </li>
              );
            })}

            {/* <li className=" border-t border-neutral-200 dark:border-neutral-700" /> */}
            {/* <li>
              <Link
                className={`flex items-center px-6 py-3 font-medium text-red-500`}
                href={'/'}
              >
                <span
                  className="w-8 mr-2 text-lg"
                  onClick={() => handleSignOut()}
                >
                  💡
                </span>
                Sign out
              </Link>
            </li> */}
          </ul>
        </div>

        <div className="border-t border-neutral-500 dark:border-neutral-300 md:hidden"></div>

        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
