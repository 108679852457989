import React, { ReactNode } from 'react';
import QuestionContent from './QuestionContent';
import { QuestionDataType } from 'data/types';

const Layout = ({
  children,
  question,
}: {
  children: ReactNode;
  question: QuestionDataType;
}) => {
  return (
    <div>
      {children}

      {question && (
        <div className='container mt-10'>
          <QuestionContent question={question} />
        </div>
      )}
    </div>
  );
};

export default Layout;
