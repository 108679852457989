import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import { UserDataType } from "data/types";
import CardAuthorBox3 from "components/CardAuthorBox3/CardAuthorBox3";
import MySlider from "components/MySlider";

export interface SectionSliderNewAuthorsProps {
  className?: string;
  heading: string;
  subHeading: string;
  authors: UserDataType[];
  itemPerRow?: number;
  type: 'newestAuthors' | 'topAuthors' 
}

const SectionSliderAuthors: FC<SectionSliderNewAuthorsProps> = ({
  heading = "Suggestions for discovery",
  subHeading = "Popular places to recommends for you",
  className = "",
  authors,
  itemPerRow = 5,
  type
}) => {
  return (
    <div className={`nc-SectionSliderNewAuthors ${className}`}>
      <Heading desc={subHeading}>
        {heading}
      </Heading>
      <MySlider
        itemPerRow={itemPerRow}
        data={authors}
        renderItem={(item, index) => (
          <CardAuthorBox3 key={index} author={item} type={type} index={index} />
        )}
      />
    </div>
  );
};

export default SectionSliderAuthors;
