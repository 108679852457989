import { FC } from 'react';
import Link from 'components/Link';
import { PostDataType } from 'types/types';
import Badge from 'components/UI/Badge/Badge';
import Button from 'components/Button/Button';

export interface HeroSectionCardProps {
  className?: string;
  post: PostDataType;
}

const HeroSectionCard: FC<HeroSectionCardProps> = ({
  className = '',
  post,
}) => {
  const { title, description, link, category } = post;
  return (
    <div
      className={`nc-Card5 relative p-5 group border border-neutral-200 hover:shadow-lg transition-shadow dark:border-neutral-700 rounded-3xl bg-white dark:bg-neutral-900 ${className}`}
    >
      <Link href={link} className="absolute inset-0 rounded-lg"></Link>

      <div className="flex flex-col items-start h-full">
        <Badge name={category || 'tools'} />
        <h2
          className="block text-base font-semibold text-neutral-800 dark:text-neutral-200 mt-2"
          title={title}
        >
          <Link href={link} className="line-clamp-2" title={title}>
            {title}
          </Link>
        </h2>
        <span className="block text-sm text-neutral-500 dark:text-neutral-300 mt-2">
          {description}
        </span>
        <Button
          sizeClass="py-1 px-4"
          href={link}
          pattern="custom"
          className="gap-2 mt-auto justify-self-end self-end sm:text-sm"
        >
          Read More
        </Button>
      </div>
    </div>
  );
};

export default HeroSectionCard;
