import { createSlice } from '@reduxjs/toolkit';
import { CategoryDataType, TrendingCategoriesDataType } from 'data/types';
import axiosInstance from '../services/axiosInstance';
import catchErrors from '../services/catchErrors';
import { AppDispatch as Dispatch } from './store';
import { RootState } from './store';

const initialState = {
  category: {} as CategoryDataType,
  categories: [],
  subcategories: [],
  trendingCategories: [] as TrendingCategoriesDataType[],
  sortedTrendingCategories: [] as TrendingCategoriesDataType[],
  isLoading: false,
  categoriesErrors: [],
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    clearCategory: (state, action) => {
      state.category = {} as CategoryDataType;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    clearCategories: (state) => {
      state.categories = [];
    },
    setSubCategories: (state, action) => {
      state.subcategories = action.payload;
    },
    clearSubCategories: (state) => {
      state.subcategories = [];
    },
    setTrendingCategories: (state, action) => {
      state.trendingCategories = action.payload;
    },
    clearTrendingCategories: (state) => {
      state.trendingCategories = [];
    },
    setErrors: (state, action) => {
      state.categoriesErrors = action.payload;
    },
    clearErrors: (state) => {
      state.categoriesErrors = [];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    sortByTopTrending: (state) => {
      state.sortedTrendingCategories = state.trendingCategories.sort((a, b) => {
        return b.count - a.count;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCategories,
  clearCategories,
  setSubCategories,
  clearSubCategories,
  setCategory,
  clearCategory,
  setTrendingCategories,
  clearTrendingCategories,
  setErrors,
  clearErrors,
  setLoading,
  sortByTopTrending,
} = categorySlice.actions;

// --------------------------------------------------------

export const getTrendingCategories = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axiosInstance({
      url: '/api/categories/search',
      method: 'GET',
    });

    dispatch(setTrendingCategories(response.data.categories));
    dispatch(setLoading(false));
    return true;
  } catch (err) {
    console.log(`Error while getting trending categories: ${err}`);
    const errs = catchErrors(err);
    dispatch(setErrors(errs));
    dispatch(setLoading(false));
    return false;
  }
};

// --------------------------------------------------------

export const getCategories =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: '/api/categories',
        method: 'GET',
      });

      dispatch(setCategories(response.data.categories));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting categories: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getCategoryDetails =
  ({ id }: { id: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: '/api/categories/' + id,
        method: 'GET',
      });

      dispatch(setCategory(response.data.category));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting category detail: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getSubCategories =
  ({ id }: { id: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: '/api/categories/subtypes/' + id,
        method: 'GET',
      });

      dispatch(setSubCategories(response.data.subcategories));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting categories: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getSearchCategories =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: '/api/categories/search',
        method: 'GET',
      });

      dispatch(setCategories(response.data.categories));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting categories: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

export default categorySlice.reducer;
