import Heading from 'components/Heading/Heading';
import CategoryCard from 'components/CategoryCard/CategoryCard';
import { TrendingCategoriesDataType } from 'data/types';
import { FC, useEffect, useState } from 'react';
import ButtonPrimary from 'components/Button/ButtonPrimary';

export interface MainSectionGridCategoryBoxProps {
  categories: TrendingCategoriesDataType[];
  headingCenter?: boolean;
  className?: string;
}

const MainSectionGridCategoryBox: FC<MainSectionGridCategoryBoxProps> = ({
  categories,
  headingCenter = true,
  className = '',
}) => {
  const [numberOfCategoriesToDisplay, setNumberOfCategoriesToDisplay] =
    useState(10);
  const [categoriesToDisplay, setCategoriesToDisplay] = useState<
    TrendingCategoriesDataType[]
  >([]);

  const handleShowMore = () => {
    setNumberOfCategoriesToDisplay((prev) =>
      prev + 10 < categories.length ? prev + 10 : categories.length
    );
  };

  useEffect(() => {
    categories &&
      setCategoriesToDisplay(categories.slice(0, numberOfCategoriesToDisplay));
  }, [categories, numberOfCategoriesToDisplay]);

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading
        desc="Browse categories for questions..."
        isCenter={headingCenter}
      >
        Trending Categories
      </Heading>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-6 md:gap-8 mb-8">
        {categories &&
          categoriesToDisplay.map((item, i) => (
            <CategoryCard
              badge={i < 3 ? `#${i + 1}` : undefined}
              index={i}
              key={item.id || i}
              category={item}
            />
          ))}
      </div>
      {numberOfCategoriesToDisplay < categories.length && (
        <ButtonPrimary onClick={handleShowMore}>Show me more</ButtonPrimary>
      )}
    </div>
  );
};

export default MainSectionGridCategoryBox;
