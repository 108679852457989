import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch as useDispatch } from 'store/store';
import {
  getUser,
  updateProfile,
  getUserUploadURL,
  clearErrors,
  setLoading,
} from '../../../../store/auth';
import { RootState } from 'store/store';
import errors from '../../../../services/errors';

import ButtonPrimary from 'components/Button/ButtonPrimary';
import Input from 'components/Input/Input';
import Layout from '../layout';
import axios from 'axios';
import Label from 'components/Label/Label';
import Textarea from 'components/Textarea/Textarea';
import Select from 'components/Select/Select';
import { CategoryDataType } from 'data/types';
import { getCategories, getSubCategories } from 'store/category';
import { regions } from 'data/regions';
import { languages } from 'data/languages';

const DashboardEditProfile = ({ id }: { id: string }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = async () => {
      await dispatch(getUser(id));
    };
    fetchUserData();

    return () => {
      dispatch(clearErrors());
      dispatch(setLoading(false));
    };
  }, [dispatch, id]);

  const {
    user,
    isLoading,
    errors: authErrors,
  } = useSelector((state: RootState) => state.auth);

  const categories: CategoryDataType[] = useSelector(
    (state: RootState) => state.category.categories
  )

  const subcategories: CategoryDataType[] = useSelector(
    (state: RootState) => state.category.subcategories
  )

  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('')
  const [businessUrl, setBusinessUrl] = useState('')
  const [linkedin, setLinkedin] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [bio, setBio] = useState('');
  const [updateProfileErrors, setUpdateProfileErrors] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState('')

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName || '');
      setLastName(user.lastName || '');
      setBusinessName(user.companyName || '')
      setBusinessUrl(user.businessUrl || '')
      setLinkedin(user.linkedin || '');
      setFacebook(user.facebook || '');
      setInstagram(user.instagram || '');
      setTwitter(user.twitter || '');
      setBio(user.bio || '');
      setSelectedCategory(`${user.categoryExpertise}-${user.categoryExpertiseId}` || '');
      setSelectedSubCategory(user.subCategoryExpertise || '');
      setSelectedRegion(user.region || '');
      setSelectedLanguage(user.language || '');
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getCategories());
    };

    fetchData();
  }, [dispatch])

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (selectedCategory) {
        await dispatch(getSubCategories({ id: selectedCategory.split('-')[1] }));
      }
    };

    fetchSubCategories();
  }, [dispatch, selectedCategory])

  const [selectedImage, setSelectedImage] = useState<File>();
  const [imageError, setImageError] = useState<{ message: string }[]>([]);

  // This function will be triggered when the file field change
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check the duration of the image
    const target = e.target;
    if (target && target.files && target.files[0]) {
      // Set selected image to the image that was selected
      setSelectedImage(target.files[0]);
    }
    return;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUpdateProfileErrors('')
    console.log('Updating profile...');

    if (selectedImage) {
      dispatch(setLoading(true));
      // get a signed url to upload to s3
      const res = await dispatch(
        getUserUploadURL({ id, fileType: selectedImage.type })
      );

      const { url: uploadURL } = res;

      try {
        console.log(`Uploading file to ${uploadURL}`);
        await axios.put(uploadURL, selectedImage, {
          headers: {
            'Content-Type': selectedImage!.type,
          },
        });

        console.log(`File uploaded successfully`);
      } catch (err) {
        // delete the question from the database
        console.log(`Error while uploading avatar image ${err}`);
      }
    }

    const response = await dispatch(
      updateProfile({
        id,
        firstName,
        lastName,
        businessName,
        businessUrl,
        linkedin,
        facebook,
        instagram,
        twitter,
        avatarUrl: selectedImage ? id : user.avatarUrl,
        bio,
        selectedCategoryId: selectedCategory.split('-')[1],
        selectedCategory: selectedCategory.split('-')[0],
        selectedSubCategory,
        selectedRegion,
        selectedLanguage
      })
    );
    if (response) {
      // Refresh this page without navigate
      window.location.reload();
    } else {  
      setUpdateProfileErrors('There was an error updating your profile.')
    }

  };

  return (
    <Layout id={id}>
      <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
        <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
          { user.isBusinessAccount ? (
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Business Name
              </span>
              <Input
                type="text"
                className="mt-1"
                placeholder="Business Name"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                required
              />
            </label>
          ) : (
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input
                type="text"
                className="mt-1"
                placeholder="John"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </label>
          )}
          { user.isBusinessAccount ? (
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Business Website
              </span>
              <Input
                type="text"
                className="mt-1"
                placeholder="https://acme.com"
                value={businessUrl}
                onChange={(e) => setBusinessUrl(e.target.value)}
              />
            </label>
          ) : (
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input
                type="text"
                className="mt-1"
                placeholder="Doe"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </label>
          )}
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              LinkedIn
            </span>
            <Input
              type="text"
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
              placeholder="linkedin.com"
              className="mt-1"
            />
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Facebook
            </span>
            <Input
              type="text"
              className="mt-1"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
              placeholder="facebook.com"
            />
          </label>

          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Instagram
            </span>
            <Input
              type="text"
              className="mt-1"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              placeholder="instagram.com"
            />
          </label>

          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              X
            </span>
            <Input
              type="text"
              className="mt-1"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              placeholder="twitter.com"
            />
          </label>

          <label className="block">
            <Label>Category Expertise</Label>

            <Select
              className="mt-1"
              value={selectedCategory}
              onChange={e => { setSelectedCategory(e.target.value); setSelectedSubCategory('') }}
              name="category"
            >
              <option key={`0`} value={`0`}>
                Select a category
              </option>
              {categories.map(({ id, title }) => (
                <option key={id} value={`${title}-${id}`}>
                  {title}
                </option>
              ))}
            </Select>
          </label>

          <label className="block">
            <Label className={(selectedCategory.length === 0 || subcategories.length === 0) ? 'font-normal text-gray-300 dark:text-gray-500' : ''}>Sub Category Expertise</Label>

            <Select
              className="mt-1"
              value={selectedSubCategory}
              onChange={e => setSelectedSubCategory(e.target.value)}
              name="sub-category"
              disabled={selectedCategory.length === 0 || subcategories.length === 0}
            >
              <option key={`0`} value={`0`}>
                { selectedCategory.length === 0 ? 'Choose a category' : subcategories.length === 0 ? 'No sub categories found' : 'Select a sub category' }
              </option>
              {subcategories.map(({ id, title }) => (
                <option key={id} value={title}>
                  {title}
                </option>
              ))}
            </Select>
          </label>

          <label className="block">
            <Label>Region</Label>

            <Select
              className="mt-1"
              value={selectedRegion}
              onChange={e => setSelectedRegion(e.target.value)}
              name="region"
            >
              <option key={`0`} value={`0`}>
                Select a region
              </option>
              {regions.map(({ id, name }) => (
                <option key={id} value={name}>
                  {name}
                </option>
              ))}
            </Select>
          </label>

          <label className="block">
            <Label>Language</Label>

            <Select
              className="mt-1"
              value={selectedLanguage}
              onChange={e => setSelectedLanguage(e.target.value)}
              name="language"
            >
              <option key={`0`} value={`0`}>
                Select a language
              </option>
              {languages.map(({ id, name }) => (
                <option key={id} value={name}>
                  {name}
                </option>
              ))}
            </Select>
          </label>

          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Bio
            </span>
            <Textarea
              className="mt-1"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              placeholder="Write your bio..."
              rows={6}
            />
          </label>

          {user.avatarUrl && (
            <div className="flex flex-col gap-1 ">
              <Label>Current Avatar</Label>
              {/* Show the selected image here */}
              <img src={user.avatarUrl} alt="avatar" className="mx-auto" />
            </div>
          )}
          <div className="flex flex-col gap-1">
            <Label>Upload Avatar Image</Label>

            <div className="flex justify-center items-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md h-full">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="flex flex-col items-center justify-center sm:flex-row text-sm text-neutral-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>Upload a image</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept="image/*"
                      className="sr-only"
                      onChange={handleImageChange}
                    />
                  </label>
                  {/* <p className="pl-1">or drag and drop</p> */}
                </div>
                <p className="text-xs text-neutral-500">JPG, JPEG, PNG</p>

                {selectedImage && (
                  <div className="flex flex-col gap-1 ">
                    <Label>Selected Avatar</Label>
                    {/* Show the selected image here */}
                    <img src={URL.createObjectURL(selectedImage)} alt="" />

                    <p className="text-sm mt-3 text-neutral-6000">
                      {selectedImage.name}
                    </p>
                  </div>
                )}

                {imageError.length > 0 && errors(imageError)}
              </div>
            </div>
          </div>
          { updateProfileErrors && <p className='text-red-500 md:col-span-2 text-center'>{updateProfileErrors}</p>}
          <ButtonPrimary
            className="md:col-span-2"
            type="submit"
            disabled={isLoading}
          >
            Update profile
          </ButtonPrimary>
        </form>
      </div>
    </Layout>
  );
};

export default DashboardEditProfile;
