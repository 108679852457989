import React, { FC } from 'react';
import { QuestionDataType } from 'data/types';
import AnswerCard from 'components/AnswerCard/AnswerCard';

export interface QuestionCommentListsProps {
  question: QuestionDataType;
}

const QuestionCommentLists: FC<QuestionCommentListsProps> = ({ question }) => {
  return (
    <ul className="nc-QuestionCommentLists space-y-5">
      {question?.answers?.map((answer) => (
        <AnswerCard answer={answer} />
      ))}

      {/* <ButtonPrimary className="dark:bg-primary-700 w-full">
        View full comments (+117 comments)
      </ButtonPrimary> */}
    </ul>
  );
};

export default QuestionCommentLists;
