import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch as useDispatch } from 'store/store';
import axios from 'axios';

import {
  createQuestion,
  getQuestionUploadURL,
  updateQuestion,
  clearErrors as clearQuestionErrors,
  setLoading as setQuestionLoading,
  getUploadVideoURL,
  addAnswer,
  uploadAnswer,
} from 'store/question';
import {
  getCategories,
  getSubCategories,
  clearErrors as clearCategoryErrors,
  setLoading as setCategoryLoading,
} from 'store/category';
import errors from '../../../services/errors';

import Input from 'components/Input/Input';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import Select from 'components/Select/Select';
import Textarea from 'components/Textarea/Textarea';
import Label from 'components/Label/Label';
import { CategoryDataType } from 'data/types';
import Layout from '../new/layout';

const DashboardSubmitPost = () => {
  
  const navigate = useNavigate();
  const { id: businessId } = useParams();

  const auth = useSelector((state: RootState) => state.auth);
  const { isLoading } = useSelector((state: RootState) => state.question);

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const [selectedVideo, setSelectedVideo] = useState<File>();
  const [videoError, setVideoError] = useState<{ message: string }[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getCategories());
    };

    fetchData();
  }, [dispatch]);


  useEffect(() => {
    return () => {
      dispatch(clearQuestionErrors());
      dispatch(clearCategoryErrors());
      dispatch(setQuestionLoading(false));
      dispatch(setCategoryLoading(false));
    };
  }, [dispatch]);

  // This function will be triggered when the file field change
  const handleVideoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check the duration of the video
    let duration;
    const video = document.createElement('video');
    const target = e.target;
    if (target && target.files && target.files[0]) {
      video.src = URL.createObjectURL(target.files[0]);
      video.onloadedmetadata = function () {
        URL.revokeObjectURL(video.src);
        duration = video.duration;

        if (duration > 75) {
          setVideoError([{ message: 'Video should be under 60s' }]);
          return;
        } else {
          setVideoError([]);
        }
      };

      // Set selected video to the video that was selected
      setSelectedVideo(target.files[0]);
    }
    return;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedVideo) {
      return;
    }

    if (videoError.length > 0) {
      return;
    }

    dispatch(setQuestionLoading(true));
    // get a signed url to upload to s3

    const uploadVideoURL = await dispatch(
      getUploadVideoURL({
        filename: selectedVideo.name,
        fileType: selectedVideo.type,
      })
    );

    // upload the file to s3
    try {
      console.log(`Uploading file to ${uploadVideoURL}`);
      const response = await axios.put(uploadVideoURL, selectedVideo, {
        headers: {
          'Content-Type': selectedVideo.type,
        },
      });

      console.log(`File uploaded successfully`);

      if (response.status === 200) {
        const res = await dispatch(
          uploadAnswer({ title, body, video: selectedVideo.name })
        );
        if (res) {
          setBody('');
          setSelectedVideo(undefined);
          navigate(`/`);
        }
      }
    } catch (err) {
      // delete the question from the database
      console.log(`Error while uploading file ${err}`);
    }
    dispatch(setQuestionLoading(false));
  };

  return (
    <Layout>
      <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
        <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
          <label className="block md:col-span-2">
            <Label>Title *</Label>

            <Input
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              name="title"
              className="mt-1"
              required
            />
          </label>
          <label className="block md:col-span-2">
            <Label>Description *</Label>

            <Textarea
              className="mt-1"
              rows={4}
              onChange={(e) => setBody(e.target.value)}
              name="description"
              required
            />
            <p className="mt-1 text-sm text-neutral-500">
              Brief description for your video
            </p>
          </label>

          <div className="flex flex-col gap-1 md:col-span-2">
            <Label>Upload Video *</Label>

            <div className="flex justify-center items-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md h-full">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="flex flex-col items-center justify-center sm:flex-row text-sm text-neutral-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>Upload a video</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept="video/*;capture=camera"
                      hidden
                      onChange={handleVideoChange}
                    />
                  </label>
                  {/* <p className="pl-1">or drag and drop</p> */}
                </div>
                <p className="text-xs text-neutral-500">MP4, MOV, AVI</p>

                {selectedVideo && (
                  <div className="flex flex-col gap-1 ">
                    <Label>Selected Video</Label>
                    {/* Show the selected video here */}
                    <video
                      className="w-full h-full rounded overflow-hidden"
                      src={URL.createObjectURL(selectedVideo)}
                      autoPlay
                      muted
                      controls={true}
                      controlsList="nodownload"
                    ></video>

                    <p className="text-sm mt-3 text-neutral-6000">
                      {selectedVideo.name}
                    </p>
                  </div>
                )}

                {videoError.length > 0 && errors(videoError)}
              </div>
            </div>
          </div>

          {/* Can submit an answer only if logged in */}
          {auth.currentUser && (
            <ButtonPrimary
              className="md:col-span-2"
              type="submit"
              loading={isLoading}
            >
              Submit post
            </ButtonPrimary>
          )}
        </form>

        {/* Cannot submit an answer if not logged in */}
        {!auth.currentUser && (
          <div
            className="flex flex-col items-center justify-center gap-1 m-auto mt-8 cursor-pointer"
            onClick={() => navigate('/login')}
          >
            <p>
              {errors([{ message: 'You must be logged in to submit a post' }])}
            </p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default DashboardSubmitPost;
