// @ts-ignore
import axios from 'axios';
import { auth } from '../services/firebase';

const axiosInstance = async ({ url, method, body = {}, token = null, timeout=5000 }) => {
  let firebaseToken = token;

  const user = auth.currentUser;

  if (user) {
    firebaseToken = await user.getIdToken();
  }

  const headers = firebaseToken
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${firebaseToken}`,
      }
    : {
        'Content-Type': 'application/json',
      };

  return axios({
    url: `${process.env.REACT_APP_BACKEND_URL}${url}`,
    method: method || 'GET',
    data: body,
    headers: headers,
    timeout: timeout !== undefined ? timeout : 5000,
  });
};

export default axiosInstance;