import { FC, useEffect } from 'react';
import { TrendingCategoriesDataType, UserDataType } from 'data/types';
import WidgetCategories from 'components/UI/Widgets/WidgetCategories';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { clearErrors, getTrendingCategories, setLoading } from 'store/category';
import { getNewestAuthors } from 'store/author';
import WidgetAuthors from 'components/UI/Widgets/WidgetAuthors';
import { getSearchResults } from 'store/question';
import WidgetQuestions from 'components/UI/Widgets/WidgetQuestions';

export interface SidebarProps {
  className?: string;
}

export const Sidebar: FC<SidebarProps> = ({ className = 'space-y-6 ' }) => {
  const dispatch = useAppDispatch();

  const categories: TrendingCategoriesDataType[] = useSelector(
    (state: RootState) => state.category.trendingCategories
  );

  const newestAuthors: UserDataType[] = useSelector(
    (state: RootState) => state.author.newestAuthors
  );

  const { filteredQuestions } = useSelector(
    (state: RootState) => state.question
  );

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTrendingCategories());
      dispatch(getNewestAuthors());
      dispatch(getSearchResults({ query: '' }));
    };

    fetchData();

    return () => {
      dispatch(clearErrors());
      dispatch(setLoading(false));
    };
  }, [dispatch]);

  return (
    <div className={`nc-SingleSidebar ${className}`}>
      <WidgetCategories categories={categories.slice(0, 5)} />
      <WidgetAuthors authors={newestAuthors.slice(0, 5)} />
      <WidgetQuestions questions={filteredQuestions.slice(0, 5)} />
    </div>
  );
};
