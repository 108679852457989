import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch as useDispatch } from 'store/store';
import { getUser, getUserProfile } from 'store/auth';
import {
  clearErrors,
  getUserQuestions,
  getBusinessQuestions,
  getMyVideos,
} from 'store/question';
import { useNavigate, useParams } from 'react-router-dom';

import ButtonPrimary from 'components/Button/ButtonPrimary';
import Nav from 'components/Nav/Nav';
import NavItem from 'components/NavItem/NavItem';
import ArchiveFilterListBox from 'components/ArchiveFilterListBox/ArchiveFilterListBox';
import QuestionCard from 'components/QuestionCard/QuestionCard';
import NcImage from 'components/NcImage/NcImage';
import {
  ShareIcon,
  LinkIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import VerifyIcon from 'components/VerifyIcon';
import FollowButton from 'components/FollowButton';
import NcDropDown from 'components/NcDropDown/NcDropDown';
import { SOCIALS_DATA } from 'components/SocialsShare/SocialsShare';
import AccountActionDropdown from 'components/AccountActionDropdown/AccountActionDropdown';
import Image from 'components/Image';
import ActivityCard from 'components/ActivityCard/ActivityCard';
import DashboardEditProfile from './dashboard/edit-profile/page';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import Badge from 'components/UI/Badge/Badge';
import { getUserAnswers } from 'store/author';
import AnswerCard2 from 'components/AnswerCard/AnswerCard2';

const FILTERS = [
  { name: 'Most Popular', value: 'popularity' },
  { name: 'Most Recent', value: 'date' },
];

const PageAuthor = () => {
  const [tabs, setTabs] = useState(['My Answers', 'My Questions']);
  const [tabActive, setTabActive] = useState<string>(tabs[0]);
  const [selected, setSelected] = useState(FILTERS[0]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchUserQuestions = async () => {
      await dispatch(getUserQuestions({ id: id! }));
    };

    const fetchDirectQuestions = async () => {
      await dispatch(getBusinessQuestions({ id: id! }));
    };

    const fetchUserAnswers = async () => {
      await dispatch(getUserAnswers({ id: id! }));
    };

    const fetchData = async () => {
      if (id) {
        await dispatch(getUserProfile(id));
      }
    };

    dispatch(getMyVideos(id!));

    fetchDirectQuestions();
    fetchUserAnswers();
    fetchUserQuestions();
    fetchData();

    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch, id]);

  const { userProfile: user, isLoading: authLoading } = useSelector(
    (state: RootState) => state.auth
  );
  const { userQuestions, businessQuestions, myVideos } = useSelector(
    (state: RootState) => state.question
  );
  const { userAnswers } = useSelector((state: RootState) => state.author);
  const { currentUser } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (user?.isBusinessAccount && currentUser?.id === id) {
      setTabs(['Direct Questions', 'My Videos', 'My Answers', 'My Questions']);
    }
    user?.isBusinessAccount &&
      currentUser?.id === id &&
      setTabActive('Direct Questions');
  }, [user, currentUser]);

  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };
  return (
    <div className={`nc-PageAuthor `}>
      {/* HEADER */}
      {user && !authLoading && (
        <div className="w-full">
          <div className="relative w-full h-40 md:h-60 2xl:h-72">
            <NcImage
              alt=""
              containerClassName="absolute inset-0"
              sizes="(max-width: 1280px) 100vw, 1536px"
              src="https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              className="object-cover w-full h-full"
              fill
            />
          </div>
          <div className="container -mt-10 lg:-mt-16">
            <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
              <div className="w-32 lg:w-40 flex-shrink-0 mt-12 sm:mt-0">
                <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold rounded-full w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36 ring-4 ring-white dark:ring-0 shadow-2xl z-0">
                  <Image
                    alt="Avatar"
                    src={
                      user.avatarUrl ||
                      `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&bold=true`
                    }
                    fill
                    className="object-cover"
                  />
                </div>
              </div>

              {/*  */}
              <div className="pt-5 md:pt-1 lg:ml-6 xl:ml-12 flex-grow">
                <div className="max-w-screen-sm space-y-3.5">
                  <h2 className="inline-flex items-center text-2xl sm:text-3xl lg:text-4xl font-semibold">
                    <span>
                      {user.isBusinessAccount
                        ? `${user.companyName}`
                        : `${user.firstName} ${
                            user.lastName ? user.lastName : ''
                          }`}
                    </span>
                    {user.emailVerified && (
                      <VerifyIcon
                        className="ml-2"
                        iconClass="w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8"
                        badgeColor={
                          user.score > 150
                            ? '#F7EF8A'
                            : user.score > 50
                            ? '#C0C0C0'
                            : '#CD7F32'
                        }
                      />
                    )}
                  </h2>
                  <br />
                  {user?.subCategoryExpertise && (
                    <div className="flex space-x-3">
                      <Badge
                        name={user.subCategoryExpertise}
                        color={'purple'}
                      />
                      <Badge
                        name={
                          <span>
                            OraklyScore<sup>TM</sup>: {user.score}
                          </span>
                        }
                        color={'purple'}
                      />
                    </div>
                  )}
                  <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                    {user.bio}
                  </span>
                  <div className="flex space-x-3">
                    <ActivityCard title="Questions" count={user.questions} />
                    <ActivityCard
                      title="Answers"
                      count={user.answers}
                      isQuestion={false}
                    />
                  </div>

                  {user.businessUrl && (
                    <a
                      className="flex items-center text-xs font-medium space-x-2.5 cursor-pointer text-neutral-500 dark:text-neutral-400 truncate"
                      href={user.businessUrl}
                      target="_blank"
                    >
                      <LinkIcon className="flex-shrink-0 w-4 h-4" />
                      <span className="text-neutral-700 dark:text-neutral-300 truncate">
                        {user.businessUrl}
                      </span>
                    </a>
                  )}

                  <div
                    className={`nc-SocialsList flex space-x-3 mt-2 text-2xl text-neutral-6000 dark:text-neutral-300`}
                  >
                    {user.facebook && (
                      <a
                        className="block w-6 h-6"
                        href={user.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Facebook"
                      >
                        <FaFacebook className="block w-5 h-5" />
                      </a>
                    )}
                    {user.twitter && (
                      <a
                        className="block w-6 h-6"
                        href={user.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Twitter"
                      >
                        <FaTwitter className="block w-5 h-5" />
                      </a>
                    )}
                    {user.linkedin && (
                      <a
                        className="block w-6 h-6"
                        href={user.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Linkedin"
                      >
                        <FaLinkedin className="block w-5 h-5" />
                      </a>
                    )}
                    {user.instagram && (
                      <a
                        className="block w-6 h-6"
                        href={user.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Instagram"
                      >
                        <FaInstagram className="block w-5 h-5" />
                      </a>
                    )}
                  </div>
                </div>
              </div>

              {user.isBusinessAccount ? (
                <ButtonPrimary
                  fontSize="text-sm md:text-base font-medium"
                  sizeClass="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
                  onClick={() =>
                    navigate(
                      user?.isBusinessAccount && currentUser?.id === id
                        ? `/answers/business/${user.id}`
                        : `/questions/business/${user.id}`
                    )
                  }
                >
                  {user?.isBusinessAccount && currentUser?.id === id
                    ? 'Upload an Answer'
                    : 'Ask A Question'}
                </ButtonPrimary>
              ) : (
                <div className="absolute md:static left-5 right-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex justify-end">
                  <FollowButton
                    isFollowing={false}
                    fontSize="text-sm md:text-base font-medium"
                    sizeClass="px-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
                  />

                  <div className="mx-2">
                    <NcDropDown
                      className="flex-shrink-0 flex items-center justify-center focus:outline-none h-10 w-10 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 text-neutral-700 dark:text-neutral-200 rounded-full"
                      renderTrigger={() => <ShareIcon className="h-5 w-5" />}
                      onClick={() => {}}
                      data={SOCIALS_DATA}
                    />
                  </div>

                  <AccountActionDropdown containerClassName="h-10 w-10 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* ====================== END HEADER ====================== */}
      {currentUser && currentUser.id === id && (
        <div className={`nc-LayoutPage relative`}>
          <div
            className={`absolute h-[400px] top-0 left-0 right-0 w-full bg-primary-100 dark:bg-neutral-800 bg-opacity-25 dark:bg-opacity-40`}
          />
          <div className="container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28">
            {/* CONTENT */}
            <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
              <DashboardEditProfile id={id!} />
            </div>
          </div>
        </div>
      )}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* tabs FILTER */}
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <Nav className="sm:space-x-2">
              {tabs.map((item, index) => (
                <NavItem
                  key={index}
                  isActive={tabActive === item}
                  onClick={() => handleClickTab(item)}
                >
                  {item}
                </NavItem>
              ))}
            </Nav>
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
            <div className="flex justify-end">
              <ArchiveFilterListBox
                lists={FILTERS}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          </div>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {tabActive === 'Direct Questions' && (
              <>
                {businessQuestions?.map((question) => (
                  <QuestionCard key={question.id} question={question} />
                ))}
              </>
            )}

            {tabActive === 'My Videos' && (
              <>
                {myVideos?.map((answer) => (
                  <AnswerCard2
                    key={answer.id}
                    answer={answer}
                    avatarUrl={
                      user.avatarUrl ||
                      `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&bold=true`
                    }
                  />
                ))}
              </>
            )}

            {tabActive === 'My Answers' && (
              <>
                {userAnswers?.map((answer) => (
                  <AnswerCard2
                    key={answer.id}
                    answer={answer}
                    avatarUrl={
                      user.avatarUrl ||
                      `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&bold=true`
                    }
                  />
                ))}
              </>
            )}

            {tabActive === 'My Questions' && (
              <>
                {userQuestions?.map((question) => (
                  <QuestionCard key={question.id} question={question} />
                ))}
              </>
            )}
          </div>

          {/* PAGINATION */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {/* <Pagination /> */}
            <ButtonPrimary>Show me more</ButtonPrimary>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageAuthor;
