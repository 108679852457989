import { FC, useEffect, useRef } from 'react';
import Tag from 'components/Tag/Tag';
import IndividualsContent from './IndividualsAboutContent';
import { DEMO_TAGS } from 'data/taxonomies';

const demoTags = DEMO_TAGS.filter((_, i) => i < 9);

export interface SingleContentProps {}

const SingleContent: FC<SingleContentProps> = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleProgressIndicator = () => {
      const entryContent = contentRef.current;
      const progressBarContent = progressRef.current;

      if (!entryContent || !progressBarContent) {
        return;
      }

      const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
      let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      let scrolled = (winScroll / totalEntryH) * 100;

      progressBarContent.innerText = scrolled.toFixed(0) + '%';
    };

    const handleProgressIndicatorHeadeEvent = () => {
      window?.requestAnimationFrame(handleProgressIndicator);
    };
    handleProgressIndicator();
    window?.addEventListener('scroll', handleProgressIndicatorHeadeEvent);
    return () => {
      window?.removeEventListener('scroll', handleProgressIndicatorHeadeEvent);
    };
  }, []);

  return (
    <div className="relative">
      <div className="nc-SingleContent space-y-10">
        {/* ENTRY CONTENT */}
        <div
          id="single-entry-content"
          className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
          ref={contentRef}
        >
          <IndividualsContent />
        </div>

        {/* TAGS */}
        <div className="max-w-screen-md mx-auto flex flex-wrap">
          {demoTags.map((item) => (
            <Tag hideCount key={item.id} tag={item} className="mr-2 mb-5" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleContent;
