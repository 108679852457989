import React, { FC, useState } from 'react';
import PostCardSaveAction from 'components/PostCardSaveAction/PostCardSaveAction';
import { QuestionDataType } from 'data/types';
import CategoriesBadgeList from 'components/CategoriesBadgeList/CategoriesBadgeList';
import PostCardLikeAndComment from 'components/PostCardLikeAndComment/PostCardLikeAndComment';
import QuestionCardMeta from 'components/QuestionCardMeta/QuestionCardMeta';
import QuestionFeaturedMedia from 'components/QuestionFeaturedMedia/QuestionFeaturedMedia';
import Link from 'components/Link';

export interface QuestionCardProps {
  className?: string;
  question: QuestionDataType;
  ratio?: string;
  hiddenAuthor?: boolean;
  query?: string;
  filterBy?: string;
  isSearchPage?: boolean;
}

const QuestionCard: FC<QuestionCardProps> = ({
  className = 'h-full',
  question,
  hiddenAuthor = false,
  ratio = 'aspect-w-4 aspect-h-3',
  query,
  filterBy,
  isSearchPage,
}) => {
  const { title, createdAt, categories, user, id, visibility } = question;

  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      //
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
      >
        <div>
          <QuestionFeaturedMedia question={question} isHover={isHover} />
        </div>
      </div>
      <Link href={`/questions/${id}`} className="absolute inset-0"></Link>
      <span className="absolute top-3 inset-x-3 z-10">
        <CategoriesBadgeList categories={categories} />
      </span>

      <div className="p-4 flex flex-col space-y-3">
        {!hiddenAuthor ? (
          <QuestionCardMeta
            date={createdAt}
            author={user}
            visibility={visibility}
          />
        ) : (
          <span className="text-xs text-neutral-500">
            {createdAt.toLocaleString('en-UK', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </span>
        )}
        <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <span className="line-clamp-2" title={title}>
            {title}
          </span>
        </h3>
        <div className="flex items-end justify-between mt-auto">
          <PostCardLikeAndComment
            className="relative"
            hideComment={true}
            question={question}
            query={query}
            filterBy={filterBy}
            isSearchPage={isSearchPage}
          />
          <PostCardSaveAction className="relative" />
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
