import ForTrainers from 'app/(singles)/ForTrainers';
import Layout from '../layout';
import SingleHeader from 'components/UI/Single/SingleHeader';

const TrainersPageAbout = () => {
  return (
    <Layout content={<ForTrainers />}>
      <header className="relative py-10 z-10 md:py-14 lg:py-20 bg-neutral-900 dark:bg-black">
        {/* SINGLE HEADER */}
        <div className="dark container relative z-10">
          <div className="max-w-screen-xl">
            <SingleHeader
              title="Are you looking for a new way to sell your courses, training and teaching by answering questions directly from users who want your expertise?"
              tagName="For Trainers/Educators"
              href="/trainers-educators"
              date="08/13/2024"
              readingTime="2 min read"
            />
          </div>
        </div>
      </header>
    </Layout>
  );
};

export default TrainersPageAbout;
