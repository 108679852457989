import React, { useState, useEffect } from 'react';
import { RootState, useAppDispatch as useDispatch } from 'store/store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TrendingCategoriesDataType, UserDataType } from 'data/types';
import { DEMO_CATEGORIES } from 'data/taxonomies';
import Pagination from 'components/Pagination/Pagination';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import ArchiveFilterListBox from 'components/ArchiveFilterListBox/ArchiveFilterListBox';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionGridCategoryBox from 'components/SectionGridCategoryBox/SectionGridCategoryBox';
import ButtonSecondary from 'components/Button/ButtonSecondary';
import SectionSliderAuthors from 'components/SectionSliderAuthors/SectionSliderAuthors';
import Image from 'components/Image';
import QuestionCard from 'components/QuestionCard/QuestionCard';
import MainSectionGridCategoryBox from 'components/SectionGridCategoryBox/MainSectionGridCategoryBox';

import {
  getCategoryDetails,
  clearCategory,
  getTrendingCategories,
} from 'store/category';
import { clearErrors, getTopAuthors, setLoading } from 'store/author';
import ModalCategories from 'components/UI/Modal/ModalCategories';

// Tag and category have same data type - we will use one demo data
// const posts: PostDataType[] = DEMO_POSTS.filter((_, i) => i < 16);

const CategoryPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const topAuthors: UserDataType[] = useSelector(
    (state: RootState) => state.author.topAuthors
  );

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      await dispatch(getCategoryDetails({ id: id! }));
    };
    const fetchData = async () => {
      id && dispatch(getTopAuthors(id));
    };

    fetchCategoryDetails();
    fetchData();

    return () => {
      dispatch(clearCategory({}));
      dispatch(clearErrors());
      dispatch(setLoading(false));
    };
  }, [dispatch, id]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTrendingCategories());
    };

    fetchData();

    return () => {
      dispatch(clearErrors());
      dispatch(setLoading(false));
    };
  }, [dispatch]);

  const { category } = useSelector((state: RootState) => state.category);
  const categories: TrendingCategoriesDataType[] = useSelector(
    (state: RootState) => state.category.trendingCategories
  );

  const FILTERS = [
    { name: 'Most Popular', value: 'popularity' },
    { name: 'Most Recent', value: 'date' },
  ];

  const [selected, setSelected] = useState(FILTERS[0]);

  return (
    <div className={`nc-PageArchive`}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
          <Image
            alt="archive"
            fill
            src="https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
            sizes="(max-width: 1280px) 100vw, 1536px"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
              {category?.title}
            </h2>
            <span className="block mt-4 text-neutral-300">
              {category?.questions?.length} posts
            </span>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container pt-10 lg:pt-20 space-y-16 lg:space-y-28">
        <div className="flex flex-col space-y-16 lg:space-y-28">
          {category?.subcategories?.length > 0 && (
            <MainSectionGridCategoryBox
              headingCenter={false}
              categories={category?.subcategories}
            />
          )}

          {!!category?.questions?.length && (
            <div>
              <div className="flex flex-col sm:justify-between sm:flex-row">
                <div className="flex space-x-2.5">
                  <ModalCategories
                    categories={categories}
                    triggerText="Other Categories"
                  />
                </div>
                <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
                <div className="flex justify-end">
                  <ArchiveFilterListBox
                    lists={FILTERS}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </div>

              {/* LOOP ITEMS */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
                {category.questions.map((question) => (
                  <QuestionCard key={question.id} question={question} />
                ))}
              </div>

              {/* PAGINATIONS */}
              <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
                <Pagination />
                <ButtonPrimary>Show me more</ButtonPrimary>
              </div>
            </div>
          )}
        </div>

        {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionGridCategoryBox
            categories={DEMO_CATEGORIES.filter((_, i) => i < 10)}
          />
          <div className="text-center mx-auto mt-10 md:mt-16">
            <ButtonSecondary loading>Show me more</ButtonSecondary>
          </div>
        </div> */}

        {/* === SECTION 5 === */}
        <SectionSliderAuthors
          heading="Category Captains"
          subHeading="Discover our category captains..."
          type="topAuthors"
          authors={topAuthors}
        />

        {/* SUBCRIBES */}
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default CategoryPage;
