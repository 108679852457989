import { FC } from 'react';
import Avatar from 'components/Avatar/Avatar';
import { CurrentUserType } from 'data/types';
import Link from 'components/Link';

export interface AuthorCardMetaProps {
  className?: string;
  date: Date;
  author: CurrentUserType;
  hiddenAvatar?: boolean;
  avatarSize?: string;
}

const AuthorCardMeta: FC<AuthorCardMetaProps> = ({
  className = 'leading-none text-xs',
  date,
  author,
  hiddenAvatar = false,
  avatarSize = 'h-7 w-7 text-sm',
}) => {
  return (
    <div
      className={`nc-AuthorCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
    >
      <Link
        href={`/profile/${author._id}`}
        className="relative flex items-center space-x-2"
      >
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={author?.avatarUrl}
            userName={author?.firstName}
          />
        )}
        <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
          {author.companyName ? author.companyName : author?.firstName}
        </span>
      </Link>
      <>
        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
          ·
        </span>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal">
          {new Date(date).toLocaleDateString('en-UK', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </span>
      </>
    </div>
  );
};

export default AuthorCardMeta;
