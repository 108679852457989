import React, { FC } from 'react';
import PostCardCommentBtn from 'components/PostCardCommentBtn/PostCardCommentBtn';
import PostCardLikeAction from 'components/PostCardLikeAction/PostCardLikeAction';
import { QuestionDataType } from 'data/types';

export interface PostCardLikeAndCommentProps {
  className?: string;
  itemClass?: string;
  hiddenCommentOnMobile?: boolean;
  hideComment?: boolean;
  useOnSinglePage?: boolean;
  question?: QuestionDataType;
  query?: string;
  filterBy?: string;
  isSearchPage?: boolean;
}

const PostCardLikeAndComment: FC<PostCardLikeAndCommentProps> = ({
  className = '',
  itemClass = 'px-3 h-8 text-xs',
  hiddenCommentOnMobile = true,
  hideComment = false,
  useOnSinglePage = false,
  question,
  query,
  filterBy,
  isSearchPage
}) => {
  return (
    <div
      className={`nc-PostCardLikeAndComment flex items-center space-x-2 ${className}`}
    >
      <PostCardLikeAction
        className={itemClass}
        id={question?.id}
        upVotes={question?.upVotes}
        downVotes={question?.downVotes}
        isQuestion={true}
        query={query}
        filterBy={filterBy}
        isSearchPage={isSearchPage}
      />
      <PostCardCommentBtn
        className={`${
          hideComment
            ? 'hidden'
            : hiddenCommentOnMobile
            ? 'hidden sm:flex'
            : 'flex'
        }  ${itemClass}`}
        isATagOnSingle={useOnSinglePage}
        answersCount={question?.answers?.length || 0}
      />
      { question?.business && (
        <>
        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
          ·
        </span>
        { question?.visibility === 'private' ? (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          </svg>
        
        ) }
        </>
      ) }
    </div>
  );
};

export default PostCardLikeAndComment;
