import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
} from 'firebase/auth';
import { createSlice } from '@reduxjs/toolkit';
import { auth, provider } from '../services/firebase';
import { CurrentUserType, UserDataType } from 'data/types';

import { AppDispatch as Dispatch } from './store';
import axiosInstance from '../services/axiosInstance';
import catchErrors from '../services/catchErrors';

const initialState = {
  currentUser: null as CurrentUserType | null,
  isAuthenticating: true,
  isLoading: false,
  errors: [],
  user: {} as UserDataType,
  userProfile: {} as UserDataType,
  users: [] as UserDataType[],
  newestAuthors: [] as UserDataType[],
  topAuthors: [] as UserDataType[]
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setNewestAuthors: (state, action) => {
      state.newestAuthors = action.payload;
    },
    setTopAuthors: (state, action) => {
      state.topAuthors = action.payload;
    },
    setIsAuthenticating: (state, action) => {
      state.isAuthenticating = action.payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clearErrors: (state) => {
      state.errors = [];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentUser,
  setUser,
  setUserProfile,
  setUsers,
  setNewestAuthors,
  setTopAuthors,
  setIsAuthenticating,
  clearCurrentUser,
  setErrors,
  clearErrors,
  setLoading,
} = authSlice.actions;

// --------------------------------------------------------

export const signUp =
  ({
    firstName,
    lastName,
    email,
    password,
    isBusinessAccount,
    companyName,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    isBusinessAccount: boolean;
    companyName: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      await axiosInstance({
        url: '/api/auth/signup',
        method: 'POST',
        body: {
          firstName,
          lastName,
          email,
          password,
          isBusinessAccount,
          companyName,
        },
      });

      try {
        const signInRes = await signInWithEmailAndPassword(
          auth,
          email,
          password // Remove firstName and lastName arguments
        );
        console.log('Response from signing in: ', signInRes);
      } catch (error) {
        console.log('Error while signing in with email and password: ', error);
      }
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while signing up: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const signIn =
  ({ email, password }: { email: string; password: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    try {
      await signInWithEmailAndPassword(auth, email, password);
      dispatch(setLoading(false));
      return true;
    } catch (error: any) {
      console.log('Error while signing in', error);
      dispatch(
        setErrors([
          {
            message: (error as Error).message,
          },
        ])
      );
    }
    dispatch(setLoading(false));
    return false;
  };

// --------------------------------------------------------

export const signInWithGoogle = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    const token = await user.getIdToken();

    const {
      uid,
      displayName: name,
      email,
      photoURL: avatarUrl,
      emailVerified,
    } = user;

    try {
      await axiosInstance({
        url: '/api/auth/signin',
        method: 'POST',
        body: {
          uid,
          name,
          email,
          avatarUrl,
          emailVerified,
          token,
        },
        token: null, // Add token property here
      });
    } catch (err: unknown) {
      console.log(`Error while signing in with Google: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      return false;
    }

    dispatch(setLoading(false));
    return true;
  } catch (error: unknown) {
    console.log('Error while signing in with Google', error);
    dispatch(
      setErrors([
        {
          message: (error as Error).message,
        },
      ])
    );
  }
  dispatch(setLoading(false));
  return false;
};

// --------------------------------------------------------

export const forgotPassword =
  ({ email }: { email: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));

    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent!');
      dispatch(clearErrors());
      dispatch(setLoading(false));
      return true;
    } catch (error: unknown) {
      console.log('Error while sending password reset email ', error);
      dispatch(
        setErrors([
          {
            message: (error as Error).message,
          },
        ])
      );
    }
    dispatch(setLoading(false));
    return false;
  };

// --------------------------------------------------------

export const updateProfile =
  ({
    id,
    firstName,
    lastName,
    businessName,
    businessUrl,
    linkedin,
    facebook,
    instagram,
    twitter,
    avatarUrl,
    bio,
    selectedCategoryId,
    selectedCategory,
    selectedSubCategory,
    selectedRegion,
    selectedLanguage
  }: {
    id: string;
    firstName: string;
    lastName: string;
    businessName: string,
    businessUrl: string,
    linkedin: string;
    facebook: string;
    instagram: string;
    twitter: string;
    avatarUrl: string;
    bio: string
    selectedCategoryId: string
    selectedCategory: string
    selectedSubCategory: string
    selectedRegion: string
    selectedLanguage: string
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance({
        url: '/api/auth/update',
        method: 'POST',
        body: {
          id,
          firstName,
          lastName,
          businessName,
          businessUrl,
          linkedin,
          facebook,
          instagram,
          twitter,
          avatarUrl,
          bio,
          selectedCategoryId,
          selectedCategory,
          selectedSubCategory,
          selectedRegion,
          selectedLanguage
        },
      });

      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while signing up: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getUser = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axiosInstance({
      url: `/api/auth/profile/${id}`,
      method: 'GET',
    });

    dispatch(setUser(response.data.user));
    dispatch(setLoading(false));
    return true;
  } catch (err) {
    console.log(`Error while getting user: ${err}`);
    const errs = catchErrors(err);
    dispatch(setErrors(errs));
    dispatch(setLoading(false));
    return false;
  }
};

// --------------------------------------------------------


export const getUserProfile = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axiosInstance({
      url: `/api/auth/profile/${id}`,
      method: 'GET',
    });

    dispatch(setUserProfile(response.data.user));
    dispatch(setLoading(false));
    return true;
  } catch (err) {
    console.log(`Error while getting user: ${err}`);
    const errs = catchErrors(err);
    dispatch(setErrors(errs));
    dispatch(setLoading(false));
    return false;
  }
};

// --------------------------------------------------------

export const getUsers = () => async (dispatch: Dispatch) => {
  console.log('getUsers');
  try {
    dispatch(setLoading(true));
    const response = await axiosInstance({
      url: `/api/auth/users`,
      method: 'GET',
    });

    dispatch(setLoading(false));
    dispatch(setUsers(response.data.users));
    return true;
  } catch (err) {
    console.log(`Error while getting user: ${err}`);
    const errs = catchErrors(err);
    dispatch(setErrors(errs));
    dispatch(setLoading(false));
    return false;
  }
};


// --------------------------------------------------------

export const getUserUploadURL =
  ({ id, fileType }: { id: string; fileType: string }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/auth/upload?id=${id}&fileType=${fileType}`,
        method: 'GET',
      });

      return { url: response.data.url };
    } catch (err) {
      console.log(`Error while getting upload URL: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return { url: '', questionId: '' };
    }
  };
// --------------------------------------------------------

export default authSlice.reducer;
