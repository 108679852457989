import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch as useDispatch } from '../../../store/store';

import Input from 'components/Input/Input';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import NcLink from 'components/NcLink/NcLink';
import Heading2 from 'components/Heading/Heading2';
import Layout from '../layout';

import success from 'services/success';
import { forgotPassword } from 'store/auth';

const PageForgotPass = () => {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await dispatch(forgotPassword({ email }));

    if (response) {
      setSent(true);
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
  };

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
        <Heading2>Forgot password</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Welcome to our Orakly community!
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/* SUCCESS */}
        {sent &&
          success([
            {
              message: 'Please check your email to reset password.',
            },
            {
              message: 'Redirecting you to login in 5s...',
            },
          ])}

        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Email address
            </span>
            <Input
              type="email"
              placeholder="example@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1"
            />
          </label>
          <ButtonPrimary type="submit">Continue</ButtonPrimary>
        </form>

        {/* ==== */}
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Go back for {` `}
          <NcLink href="/login">Sign in</NcLink>
          {` / `}
          <NcLink href="/signup">Sign up</NcLink>
        </span>
      </div>
    </Layout>
  );
};

export default PageForgotPass;
