import { useEffect, useMemo } from 'react';
import HeaderLogged from 'components/Header/HeaderLogged';
import Header from 'components/Header/Header';
import Header2 from 'components/Header/Header2';
import { useLocation } from 'react-router-dom';
import MainHeader from 'components/Header/MainHeader';

const SiteHeader = () => {
  let { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  const headerComponent = useMemo(() => {
    let HeadComponent = MainHeader;

    switch (pathname) {
      case '/':
        HeadComponent = MainHeader;
        break;
      case '/home-2':
        HeadComponent = Header2;
        break;
      case '/home-3':
        HeadComponent = HeaderLogged;
        break;
      case '/home-4':
        HeadComponent = Header;
        break;

      default:
        break;
    }

    return <HeadComponent />;
  }, [pathname]);

  return <>{headerComponent}</>;
};
export default SiteHeader;
