import { FC } from 'react';
import SingleTitle from 'app/(singles)/SingleTitle';
import logoImg from '../../../assets/images/main-logo.png';
import Avatar from 'components/Avatar/Avatar';
import Badge from '../Badge/Badge';

export interface SingleHeaderProps {
  titleMainClass?: string;
  className?: string;
  title: string;
  tagName: string;
  href: string;
  date: string;
  readingTime: string;
}

const SingleHeader: FC<SingleHeaderProps> = ({
  titleMainClass,
  className = '',
  title,
  tagName,
  href,
  date,
  readingTime,
}) => {
  return (
    <>
      <div className={`nc-SingleHeader ${className}`}>
        <div className="space-y-5">
          <Badge name={tagName} color="red" href={href} />
          <SingleTitle
            mainClass={titleMainClass}
            className="lg:max-w-full"
            title={title}
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <div
            className={`nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0`}
          >
            <Avatar
              radius="rounded-full shadow-inner"
              sizeClass="h-10 w-10 sm:h-11 sm:w-11 text-xl"
              imgUrl={logoImg}
              userName="Craig A. Taylor"
            />
            <div className="ml-3">
              <span className="block font-semibold">Craig A. Taylor</span>
              <div className="text-xs mt-[6px]">
                <span className="text-neutral-700 dark:text-neutral-300">
                  {date}
                </span>
                <span className="mx-2 font-semibold">·</span>
                <span className="text-neutral-700 dark:text-neutral-300">
                  {readingTime}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleHeader;
