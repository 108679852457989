import { FC } from 'react';
import NcImage from 'components/NcImage/NcImage';
import Badge from 'components/UI/Badge/Badge';
import Link from 'components/Link';
import trendingCategoriesPlaceholder from '../../assets/images/trending-topics-placeholder.jpg';
import { TrendingCategoriesDataType } from 'data/types';
import { COLORS } from 'data/colors';

export interface CategoryCardProps {
  className?: string;
  category: TrendingCategoriesDataType;
  badge?: string | undefined;
  index: number;
}

const CategoryCard: FC<CategoryCardProps> = ({
  className = '',
  category,
  badge,
  index,
}) => {
  const { id: categoryId, count, title, thumbnail } = category;
  return (
    <Link
      href={`/category/${categoryId}`}
      className={`nc-CardCategory2 relative flex flex-col items-center justify-center text-center px-3 py-5 sm:p-6 bg-white dark:bg-neutral-900 rounded-3xl transition-colors ${className}`}
    >
      {badge && (
        <Badge
          color={COLORS[index % 7]}
          name={badge}
          className="absolute -top-2 sm:top-3 left-3"
        />
      )}
      <NcImage
        containerClassName={`relative flex-shrink-0 w-20 h-20 rounded-full shadow-lg overflow-hidden z-0`}
        src={thumbnail || trendingCategoriesPlaceholder}
        fill={true}
        sizes="80px"
        alt="trending categories"
        className="object-cover"
      />
      <div className="mt-3">
        <h2 className={`text-base font-semibold`}>{title}</h2>
        <span
          className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
        >
          {count} posts
        </span>
      </div>
    </Link>
  );
};

export default CategoryCard;
