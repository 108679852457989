import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch as Dispatch, RootState } from './store';
import axiosInstance from '../services/axiosInstance';
import catchErrors from '../services/catchErrors';

interface SubscriptionState {
    email: string,
    isLoading: boolean;
    errors: string[];
    subscriptionMessage: string,
}
  
const initialState : SubscriptionState = {
    email: '',
    isLoading: false,
    errors: [],
    subscriptionMessage: ''
  };
  
export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setEmail: (state,action) => {
            state.email = action.payload
        },
        clearEmail: (state) => {
            state.email = '';
        },
        setSubscriptionMessage: (state, action) => {
            state.subscriptionMessage = action.payload
        },
        clearSubscriptionMessage: (state) => {
            state.subscriptionMessage = ''
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        clearErrors: (state) => {
            state.errors = [];
        },
    },
  });
  
  export const {
    setEmail,
    clearEmail,
    setSubscriptionMessage,
    clearSubscriptionMessage,
    setLoading,
    setErrors,
    clearErrors,
  } = subscriptionSlice.actions;

  export default subscriptionSlice.reducer;

// --------------------------------------------------------

export const performHealthCheck = () => ( 
    async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            dispatch(setLoading(true));
            let response = await axiosInstance({
              url: '/api/mailchimp/healthCheck',
              method: 'GET',                
            });
            dispatch(setLoading(false));
            console.log(response);
            return true;
        }
        catch( err ) {
            console.log(`Error while running health check: ${err}`);
            const errs = catchErrors(err);
            dispatch(setErrors(errs));
            dispatch(setLoading(false));
            return false;
        }
    }
);

export const subscribeEmail = ({email}: {email: string}) => 
    async (dispatch: Dispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(true));
        dispatch(clearErrors());
        dispatch(clearEmail());
        dispatch(clearSubscriptionMessage());

        await axiosInstance({
            url: '/api/mailchimp/subscribe',
            method: 'POST',
            body: { 
                email 
            },
            timeout: 120000, // default timeout for mailchimp api
        });
 
        dispatch(setSubscriptionMessage('Successfully Subscribed Email!'))
        dispatch(setLoading(false));
        
        // clear the subscribe message after 3 seconds.
        setTimeout(() => {
            dispatch(clearSubscriptionMessage());
        }, 3000);    

        return true;
    }
    catch( err ) {
        console.log(`Error while subscribing email : ${err}`);
        dispatch(setSubscriptionMessage('Failed to Subscribe Email'))
        const errs = catchErrors(err);
        dispatch(setErrors(errs) );
        dispatch(setLoading(false));
        return false;
    }
}