import { FC, useState } from 'react';
import { TrendingCategoriesDataType } from 'data/types';
import CardCategory from '../cards/CardCategory';
import NcModal from 'components/NcModal/NcModal';
import Button from 'components/Button/Button';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export interface ModalCategoriesProps {
  categories: TrendingCategoriesDataType[];
  triggerText?: string;
}

const ModalCategories: FC<ModalCategoriesProps> = ({
  categories,
  triggerText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const renderModalContent = (closeModal: () => void) => {
    return (
      <div className="grid gap-6 sm:grid-cols-2 sm:py-2 md:gap-8 md:grid-cols-3 lg:grid-cols-4 xl:md:grid-cols-5">
        {categories.map((cat) => (
          <CardCategory
            key={cat.id}
            category={cat}
            size="normal"
            handleClick={closeModal} // Call closeModal here
          />
        ))}
      </div>
    );
  };

  return (
    <div className="nc-ModalCategories">
      <NcModal
        isOpenProp={isOpen}
        onCloseModal={handleCloseModal}
        renderTrigger={(openModal) => (
          <Button
            pattern="third"
            fontSize="text-sm font-medium"
            onClick={openModal}
          >
            <div>
              <span>{triggerText ? triggerText : 'Categories'}</span>
            </div>
            <ChevronDownIcon
              className="w-4 h-4 ml-2 -mr-1"
              aria-hidden="true"
            />
          </Button>
        )}
        modalTitle="Discover other categories"
        renderContent={renderModalContent}
      />
    </div>
  );
};

export default ModalCategories;
