import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { onAuthStateChanged } from 'firebase/auth';
import { auth as firebaseAuth } from './services/firebase';

import { setCurrentUser, setIsAuthenticating } from './store/auth';

import MyRoutes from './routers';
import builder from '@builder.io/react';

builder.init(`${process.env.REACT_APP_BUILDER_API_ID}`);

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (user) => {
      dispatch(setIsAuthenticating(true));
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        const currentUser = firebaseAuth.currentUser;
        console.log('Signed In!', currentUser?.email);

        const token = await currentUser?.getIdToken();
        dispatch(
          setCurrentUser({
            id: currentUser?.uid ?? '',
            email: currentUser?.email ?? '',
            name: currentUser?.displayName ?? '',
            token: token || '',
          })
        );
        dispatch(setIsAuthenticating(false));
        // ...
      } else {
        // User is signed out
        // ...
        console.log('Signed Out!');
        dispatch(setCurrentUser(null));
        dispatch(setIsAuthenticating(false));
      }
    });
  }, [dispatch]);

  return (
    <div className='bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body'>
      <MyRoutes />
    </div>
  );
}

export default App;
