import Heading2 from "components/Heading/Heading2";
import { ReactNode } from "react";
import { useLocation } from 'react-router-dom'

const Layout = ({ children }: { children: ReactNode }) => {

  const { pathname } = useLocation()

  return (
    <div className={`nc-LayoutPage relative`}>
      <header className="text-center max-w-2xl mx-auto - pt-5 sm:pt-6 lg:pt-8">
        <Heading2>{ pathname.startsWith('/answers/business') ? 'Upload your video' : 'Post Your Question'}</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          { pathname === '/questions/new' ? 'Record a short video to ask your question.' : pathname.startsWith('/questions/business') ? 'Ask a business a direct question, with a video, for a direct answer, with a video...' : 'Upload a video to use in answers to your comon questions.'}
        </span>
      </header>
      <div className="container relative pt-2 sm:pt-4 pb-16 lg:pt-8 lg:pb-28">
        {/* CONTENT */}
        <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
