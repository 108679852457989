import { TwMainColor } from "./types";

export const COLORS: TwMainColor[] = [
  "pink",
  "green",
  "yellow",
  "red",
  "indigo",
  "blue",
  "purple",
  "gray",
];
