import { FC, ChangeEvent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { signOut } from 'firebase/auth';

import { auth } from '../../services/firebase';
import { getUser, setCurrentUser } from 'store/auth';
import { useAppDispatch as useDispatch } from 'store/store';

import Logo from 'components/Logo/Logo';
import SwitchDarkMode from 'components/SwitchDarkMode/SwitchDarkMode';
import Button from '../Button/Button';
import {
  UserIcon,
  ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/outline';

import { RootState } from 'store/store';
import SearchForm from 'components/SearchForm/SearchForm';
import { clearErrors as clearAuthorErrors } from 'store/author';
import ModalCategories from 'components/UI/Modal/ModalCategories';
import { clearErrors, getTrendingCategories, setLoading } from 'store/category';
import { TrendingCategoriesDataType } from 'data/types';

export interface MainNavProps {}

const MainNav: FC<MainNavProps> = () => {
  const { user, isLoading: authLoading } = useSelector(
    (state: RootState) => state.auth
  );

  const categories: TrendingCategoriesDataType[] = useSelector(
    (state: RootState) => state.category.trendingCategories
  );

  const { currentUser } = useSelector((state: RootState) => state.auth);
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser?.id) {
        await dispatch(getUser(currentUser.id));
      }
    };

    fetchData();

    return () => {
      dispatch(clearAuthorErrors());
    };
  }, [dispatch, currentUser?.id]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTrendingCategories());
    };

    fetchData();

    return () => {
      dispatch(clearErrors());
      dispatch(setLoading(false));
    };
  }, [dispatch]);

  const handleSignOut = async () => {
    await signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log('Signed Out!');
        dispatch(setCurrentUser(null));
        navigate('/');
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchQuery(event.target.value);
  }

  const handleSubmit = () => {
    navigate(`/search?searchQuery=${searchQuery}`);
    setSearchQuery('');
    return;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="nc-MainNav1 relative z-10 bg-white dark:bg-slate-900 ">
      <div className="px-3 lg:px-10 md:px-6">
        <div className="h-20 py-5 flex justify-between items-center md:gap-5">
          <div className="flex justify-center lg:justify-start items-center space-x-4 sm:space-x-10 2xl:space-x-14 mr-3">
            <Logo />
          </div>
          <SearchForm
            value={searchQuery}
            onChange={handleChange}
            onSubmit={handleSubmit}
            handleKeyDown={handleKeyPress}
          />
          {/* User is not logged in */}
          {!currentUser && (
            <div className="flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
              <div className="hidden items-center gap-3 lg:flex">
                <ModalCategories categories={categories} />
                <SwitchDarkMode />
                <Button
                  sizeClass="py-3 px-2 sm:px-6"
                  href="/login"
                  pattern="white"
                  className="gap-2"
                >
                  <UserIcon className="h-4 w-4" />
                  <span>Login</span>
                </Button>
                <Button
                  sizeClass="py-3 px-4 sm:px-6"
                  href="/signup"
                  pattern="primary"
                >
                  Get Started
                </Button>
              </div>
              <div className="flex items-center lg:hidden">
                <SwitchDarkMode />
                <Button
                  sizeClass="py-3 px-2 sm:px-6"
                  href="/login"
                  pattern="white"
                  className="gap-2"
                >
                  <UserIcon className="h-4 w-4" />
                  <span>Login</span>
                </Button>
              </div>
            </div>
          )}

          {/* User is logged in */}
          {currentUser && user && (
            <div className="flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
              {/* LARGE SCREENS */}
              <div className="hidden items-center gap-2 lg:flex">
                <ModalCategories categories={categories} />
                <SwitchDarkMode />
                <Button
                  sizeClass="py-3 px-2 sm:px-6"
                  href={`/profile/${currentUser.id}`}
                  pattern="white"
                  className="gap-2"
                >
                  <UserIcon className="h-4 w-4" />
                  <span>{user.companyName || currentUser.name}</span>
                </Button>
                <Button
                  sizeClass="py-3 px-4 sm:px-6"
                  href="#"
                  onClick={() => handleSignOut()}
                  pattern="primary"
                >
                  Sign Out
                </Button>
              </div>

              {/* MOBILE SCREENS */}
              <div className="flex items-center lg:hidden">
                <Button
                  sizeClass="py-3 px-2 sm:px-6"
                  href={`/profile/${currentUser.id}`}
                  pattern="white"
                  className="gap-2"
                >
                  <UserIcon className="h-5 w-5" />
                </Button>
                <Button
                  sizeClass="py-3 px-2 sm:px-6"
                  href="#"
                  onClick={() => handleSignOut()}
                  pattern="white"
                  className="gap-2"
                >
                  <ArrowLeftOnRectangleIcon className="h-5 w-5" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainNav;
