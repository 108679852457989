import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getSearchResults } from '../../store/question';
import { useAppDispatch as useDispatch } from '../../store/store';
import { RootState } from 'store/store';

import ButtonPrimary from 'components/Button/ButtonPrimary';
import QuestionCard from 'components/QuestionCard/QuestionCard';
import Heading from 'components/Heading/Heading';

// Tag and category have same data type - we will use one demo data

const PageArchive = () => {
  const [query, setQuery] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSearchResults = async () => {
      await dispatch(getSearchResults({ query }));
    };

    if (query.length === 0) {
      fetchSearchResults();
      return;
    }

    if (query.length > 2) {
      fetchSearchResults();
    }
  }, [dispatch, query]);

  const { filteredQuestions } = useSelector(
    (state: RootState) => state.question
  );

  return (
    <div className="pt-4 pb-16 lg:pb-20 lg:pt-20 space-y-16 lg:space-y-28">
      <div>
        <Heading desc="Answer our most popular questions..." isCenter={false}>
          Popular Questions
        </Heading>

        {/* LOOP ITEMS */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
          {filteredQuestions?.map((question) => (
            <QuestionCard
              key={question.id}
              question={question}
              query={query}
              filterBy={`POPULAR`}
            />
          ))}
        </div>

        {/* PAGINATIONS */}
        <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
          {/* <Pagination /> */}
          <ButtonPrimary onClick={() => navigate(`/search?searchQuery=`)}>
            Show me more
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default PageArchive;
