import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEMO_POSTS } from 'data/posts';
import featured_img1 from '../../../images/featured_img1.webp';
import featured_img2 from '../../../images/featured_img2.webp';
import featured_img3 from '../../../images/featured_img3.webp';

import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import SectionTopQuestions from 'components/SectionTopQuestions/SectionTopQuestions';
import SectionSliderAuthors from 'components/SectionSliderAuthors/SectionSliderAuthors';
import MainSectionGridCategoryBox from 'components/SectionGridCategoryBox/MainSectionGridCategoryBox';

import { RootState, useAppDispatch as useDispatch } from 'store/store';

import { clearErrors, getTrendingCategories, setLoading } from 'store/category';
import { TrendingCategoriesDataType, UserDataType } from 'data/types';
import { fetchCMSData } from 'services/fetchCMSData';
import { getNewestAuthors } from 'store/author';
import SectionHero from 'components/Sections/SectionHero';
import FeaturedBlocksSection from 'components/UI/Sections/FeaturedBlocksSection';

// DEMO DATA
const MAGAZINE2_POSTS = DEMO_POSTS.filter((_, i) => i >= 0 && i < 7);

const PageHomeDemo3: React.FC = () => {
  const dispatch = useDispatch();

  const categories: TrendingCategoriesDataType[] = useSelector(
    (state: RootState) => state.category.trendingCategories
  );

  const newestAuthors: UserDataType[] = useSelector(
    (state: RootState) => state.author.newestAuthors
  );

  const { currentUser } = useSelector((state: RootState) => state.auth);

  const featuredImg = [featured_img1, featured_img2, featured_img3];

  const [isLoading, setIsLoading] = useState(false);
  const [heroSectionData, setHeroSectionData] = useState({
    title: '',
    description: '',
    posts: [],
    buttonText: 'Start Here',
    buttonURL: '/signup',
  });
  const [featuredBlockData, setFeaturedBlockData] = useState([]);
  const [selectedImage] = useState(featuredImg[Math.floor(Math.random() * 3)]);

  // Get the Hero Section data from Builder
  useEffect(() => {
    async function fetchHeroSectionData() {
      const heroSectionCMSData = await fetchCMSData('hero-section', {
        fields:
          'data.title,data.description,data.posts,' +
          (!currentUser
            ? 'data.primaryButtonText,data.primaryButtonLink'
            : 'data.secondaryButtonText,data.secondaryButtonLink'),
      });
      const featuredBlockCMSData = await fetchCMSData(
        'homepage-featured-block'
      );
      const { title, description, posts } = heroSectionCMSData;
      setHeroSectionData({
        title: title,
        description: description,
        posts: posts,
        buttonText: !currentUser
          ? heroSectionCMSData.primaryButtonText
          : heroSectionCMSData.secondaryButtonText,
        buttonURL: !currentUser
          ? heroSectionCMSData.primaryButtonLink
          : heroSectionCMSData.secondaryButtonLink,
      });
      setFeaturedBlockData(featuredBlockCMSData.featuredBlock);
    }

    fetchHeroSectionData();
  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTrendingCategories());
      dispatch(getNewestAuthors());
    };

    fetchData();

    return () => {
      dispatch(clearErrors());
      dispatch(setLoading(false));
    };
  }, [dispatch]);

  // DEMO DATA
  const POSTS = DEMO_POSTS;

  // DEMO POST FOR MAGAZINE SECTION
  const MAGAZINE1_POSTS = POSTS.filter((_, i) => i >= 0 && i < 8);
  // const MAGAZINE2_POSTS = DEMO_POSTS.filter((_, i) => i >= 0 && i < 7);

  return (
    <div className="nc-PageHomeDemo3 relative">
      <div className="container relative">
        <SectionHero
          className="pt-10 pb-10 lg:pb-2"
          posts={heroSectionData.posts}
          title={heroSectionData.title}
          desc={heroSectionData.description}
          featuredImage={selectedImage}
          href={heroSectionData.buttonURL || '/'}
          btnText={heroSectionData.buttonText}
        />

        <SectionTopQuestions />
        <FeaturedBlocksSection
          className="pt-4 pb-16"
          heading="Featured"
          desc="Where is Orakly working for experts"
          posts={featuredBlockData}
        />
        <MainSectionGridCategoryBox
          headingCenter={false}
          className="pb-16"
          categories={categories}
        />

        <div className="relative pt-4 pb-16">
          {/* <BackgroundSection /> */}
          <SectionSliderAuthors
            heading="Newest Creators"
            subHeading="See our newest experts..."
            type="newestAuthors"
            authors={newestAuthors}
          />
        </div>

        {/* <SectionBecomeAnAuthor className="py-16 lg:py-28" />

        <SectionLatestPosts
          posts={DEMO_POSTS.filter((_, i) => i > 7 && i < 16)}
          postCardName="card7"
          gridClass="sm:grid-cols-2"
          className="pb-16 lg:pb-28"
        /> */}

        <SectionSubscribe2 className="pb-16 lg:pb-28" />
      </div>
    </div>
  );
};

export default PageHomeDemo3;
