import React, { FC, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch as useDispatch } from 'store/store';

import Avatar from 'components/Avatar/Avatar';
import { NcDropDownItem } from 'components/NcDropDown/NcDropDown';
import Link from 'components/Link';
import SingleCommentForm from 'app/(singles)/SingleCommentForm';
import AnswerCardLikeReply from '../AnswerCardLikeReply/AnswerCardLikeReply';
import { CurrentUserType, AnswerDataType } from 'data/types';
import { upvoteAnswer } from 'store/question';

export interface AnswerType {
  id: string;
  date: string;
  content: string;
  author: CurrentUserType;
  like: {
    count: number;
    isLiked: boolean;
  };
  user: CurrentUserType;
}

export interface AnswerCardProps {
  className?: string;
  answer: AnswerDataType;
  size?: 'large' | 'normal';
}

const AnswerCard: FC<AnswerCardProps> = ({
  className = '',
  answer,
  size = 'large',
}) => {
  const { id } = useParams<{ id: string }>();
  const { createdAt, body, link, upVotes, user, video, status, transcript } =
    answer;
  const isLiked = upVotes.includes(id!);

  const dispatch = useDispatch();

  const handleLike = (val: boolean) => {
    dispatch(upvoteAnswer({ questionId: id!, id: answer.id }));
  };

  const actions: NcDropDownItem[] = [
    {
      id: 'edit',
      name: 'Edit',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
  </svg>`,
    },
    {
      id: 'reply',
      name: 'Reply',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
  </svg>`,
    },
    {
      id: 'report',
      name: 'Report abuse',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
  </svg>
  `,
    },
    {
      id: 'delete',
      name: 'Delete',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
  </svg>
  `,
    },
  ];

  const textareaRef = useRef(null);
  const [isReplying, setIsReplying] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const openReplyForm = () => {
    setIsReplying(true);
    setTimeout(() => {
      textareaRef.current && (textareaRef.current as any).focus();
    }, 100);
  };
  const closeReplyForm = () => {
    setIsReplying(false);
  };

  const openModalEditAnswer = () => setIsEditting(true);
  const closeModalEditAnswer = () => setIsEditting(false);

  const openModalReportAnswer = () => setIsReporting(true);
  const closeModalReportAnswer = () => setIsReporting(false);

  const openModalDeleteAnswer = () => setIsDeleting(true);
  const closeModalDeleteAnswer = () => setIsDeleting(false);

  const hanldeClickDropDown = (item: (typeof actions)[number]) => {
    if (item.id === 'reply') {
      return openReplyForm();
    }
    if (item.id === 'edit') {
      return openModalEditAnswer();
    }
    if (item.id === 'report') {
      return openModalReportAnswer();
    }
    if (item.id === 'delete') {
      return openModalDeleteAnswer();
    }
    return;
  };

  const renderAnswerForm = () => {
    return (
      <SingleCommentForm
        textareaRef={textareaRef}
        onClickSubmit={closeReplyForm}
        onClickCancel={closeReplyForm}
        className="flex-grow"
      />
    );
  };

  return (
    <>
      <div className={`nc-AnswerCard flex ${className}`} key={answer?.id}>
        <Avatar
          sizeClass={`h-6 w-6 text-base ${
            size === 'large' ? 'sm:text-lg sm:h-8 sm:w-8' : ''
          }`}
          radius="rounded-full"
          containerClassName="mt-4"
          imgUrl={answer?.user?.avatarUrl}
        />
        <div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-neutral-200 rounded-xl sm:ml-3 sm:text-base dark:border-neutral-700">
          {/* AUTHOR */}
          <div className="relative flex items-center pr-6">
            <Link
              className="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100"
              href={`/profile/${user.id}`}
            >
              {user.firstName} {user.lastName}
            </Link>
            <span className="mx-2">·</span>
            <span className="text-neutral-500 dark:text-neutral-400 text-xs line-clamp-1 sm:text-sm">
              {new Date(createdAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </span>
          </div>

          {/* CONTENT */}
          <span className="block text-neutral-700 mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-neutral-300">
            {body}
          </span>

          {link && (
            <>
              <div className="block text-neutral-700 mt-2 sm:mt-3 sm:mb-2 dark:text-neutral-300 font-semibold">
                Link -{' '}
                <a href={link} target="_blank" rel="noreferrer">
                  {link}
                </a>
              </div>
              <p className="text-red-500 text-sm mb-3">
                This is an external link, added by a business, and is not
                endorsed or recommended by Orakly...
              </p>
            </>
          )}

          {video && status === 'approved' && (
            <div className="flex flex-col justify-start mb-4">
              <video
                className="max-h-80 max-w-[50%] h-full rounded overflow-hidden"
                src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/${video}`}
                autoPlay
                muted
                controls={true}
                controlsList="nodownload"
              ></video>

              {transcript?.length > 0 &&
                transcript.map((t) => (
                  <blockquote className="my-8 text-neutral-500 dark:text-neutral-400 text-justify ">
                    "{t.transcript ? t.transcript.trim() : t.transcript}"
                  </blockquote>
                ))}
            </div>
          )}

          {/* ACTION LIKE REPLY */}
          {isReplying ? (
            renderAnswerForm()
          ) : (
            <AnswerCardLikeReply
              className={className}
              isLiked={isLiked}
              likeCount={upVotes.length}
              onClickReply={() => setIsReplying(true)}
              onClickLike={handleLike}
            />
          )}
        </div>
      </div>

      {/* <ModalEditAnswer
        show={isEditting}
        onCloseModalEditAnswer={closeModalEditAnswer}
      />
      <ModalReportItem
        show={isReporting}
        onCloseModalReportItem={closeModalReportAnswer}
      />
      <ModalDeleteAnswer
        show={isDeleting}
        onCloseModalDeleteAnswer={closeModalDeleteAnswer}
      /> */}
    </>
  );
};

export default AnswerCard;
