import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch as useDispatch } from 'store/store';
import axios from 'axios';
import { QuestionDataType } from 'data/types';

import { addAnswer, getUploadURL, setLoading, getMyVideos } from 'store/question';
import errors from '../../../services/errors';

import Label from 'components/Label/Label';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import Textarea from 'components/Textarea/Textarea';
import Select from 'components/Select/Select';

export interface QuestionCommentFormProps {
  className?: string;
  question: QuestionDataType;
  rows?: number;
}

const QuestionCommentForm: FC<QuestionCommentFormProps> = ({
  className = 'mt-5',
  question,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state.auth);
  const { isLoading } = useSelector((state: RootState) => state.question);
  const myVideos = useSelector((state: RootState) => state.question.myVideos);

  const [answer, setAnswer] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [linkTermsAgreed, setLinkTermsAgreed] = useState(false)
  const [linkError, setLinkError] = useState('')
  const [selectedVideo, setSelectedVideo] = useState<File>();
  const [selectedPreRecordedVideo, setSelectedPreRecordedVideo] = useState('')
  const [videoError, setVideoError] = useState<{ message: string }[]>([]);

  useEffect(() => {
    dispatch(getMyVideos('id'))
  }, [])

  // This function will be triggered when the file field change
  const handleVideoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check the duration of the video
    let duration;
    const video = document.createElement('video');
    const target = e.target;
    if (target && target.files && target.files[0]) {
      video.src = URL.createObjectURL(target.files[0]);
      video.onloadedmetadata = function () {
        URL.revokeObjectURL(video.src);
        duration = video.duration;

        if (duration > 75) {
          setVideoError([{ message: 'Video should be under 60s' }]);
          return;
        } else {
          setVideoError([]);
        }
      };

      // Set selected video to the video that was selected
      setSelectedVideo(target.files[0]);
    }
    return;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLinkError('')

    if (!auth.currentUser) {
      navigate('/auth/signin');
      return;
    }

    if (linkUrl && !linkTermsAgreed) {
      setLinkError('You must agree to the terms when adding a link.')
      return
    }

    if (linkUrl.split(' ').length > 1 && linkUrl.startsWith('https://') === false) {
      setLinkError('Please enter a valid url that begins with https://')
      return
    }

    if (selectedPreRecordedVideo && selectedPreRecordedVideo !== '0') {
      const res = await dispatch(addAnswer({ id: question.id, answer, link: linkUrl, preRecordedVideo: selectedPreRecordedVideo }));
      if (res) {
        navigate(`/questions/${question.id}`);
        setSelectedPreRecordedVideo('')
        setAnswer('')
        setLinkError('')
        setLinkUrl('')
        setLinkTermsAgreed(false)
      }
      return;
    }

    if (!selectedVideo) {
      const res = await dispatch(addAnswer({ id: question.id, answer, link: linkUrl }));
      if (res) {
        navigate(`/questions/${question.id}`);
        setAnswer('')
        setLinkError('')
        setLinkUrl('')
        setLinkTermsAgreed(false)
      }
      return;
    }

    dispatch(setLoading(true));
    // get a signed url to upload to s3
    const uploadURL = await dispatch(
      getUploadURL({
        questionId: question.id,
        filename: selectedVideo.name,
        fileType: selectedVideo.type,
      })
    );

    // upload the file to s3
    try {
      const response = await axios.put(uploadURL, selectedVideo, {
        headers: {
          'Content-Type': selectedVideo.type,
        },
      });

      if (response.status === 200) {
        const res = await dispatch(
          addAnswer({ id: question.id, answer, video: selectedVideo.name, link: linkUrl })
        );
        if (res) {
          navigate(`/questions/${question.id}`);
          setAnswer('');
          setSelectedVideo(undefined);
          setLinkError('')
          setLinkUrl('')
          setLinkTermsAgreed(false)
        }
      }
    } catch (err) {
      console.log(`Error while uploading file ${err}`);
    }
    dispatch(setLoading(false));

    setAnswer('')
    setLinkUrl('')
    setLinkTermsAgreed(false)
    setLinkError('')
  };

  const handlePreRecordedVideoChange = (event: { target: HTMLSelectElement }) => {
    const value = event.target.value;
    setSelectedPreRecordedVideo(value);
  };

  return (
    <form className={`mt-5 grid md:grid-cols-2 gap-6`} onSubmit={handleSubmit}>
      <label className="block md:col-span-2">
        <Textarea
          placeholder="Add to discussion"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          required={true}
          rows={4}
        />
      </label>

      { question?.business === auth.currentUser?.id && (
        <div className='flex flex-col col-span-2'>
        <label className="block md:col-span-2">
          <input
            type='url'
            placeholder="Add a Link"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            className='border-0 w-full rounded-md p-2 outline outline-1 outline-gray-200'
          />
        </label>
        <div className='flex items-start mt-4'>
        <input
          type='checkbox'
          className='border-0 outline outline-1 outline-gray-200 mr-3 mt-1'
          id="link-terms"
          checked={linkTermsAgreed}
          onChange={e => setLinkTermsAgreed(e.target.checked)}
        />
        <label htmlFor="link-terms">By clicking here, you agree that the link adheres to our guidelines. Any link not doing so will be removed...</label>
        </div>
        </div>
      ) }

      { ((question?.business === auth.currentUser?.id) && selectedVideo === undefined) && (
        <div className='flex flex-col col-span-2 mt-2'>
        <label className="block md:col-span-2">
        <Select
          className="mt-1"
          value={selectedPreRecordedVideo}
          onChange={handlePreRecordedVideoChange}
          name="pre-recorded-video"
          disabled={selectedVideo !== undefined}
        >
          <option key={`0`} value={`0`}>
          { myVideos.length === 0 ? 'No videos found' : 'Select a video' }
          </option>
          {myVideos.map((video) => (
            <option key={video.id} value={video.id}>
              {video.title}
            </option>
          ))}
        </Select>
        </label>
        </div>
      ) }
      { (!selectedPreRecordedVideo || selectedPreRecordedVideo === '0') && <div className="flex flex-col gap-1">
        <Label>Upload Video</Label>

        <div className="flex justify-center items-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md h-full">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-neutral-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            <div className="flex flex-col items-center justify-center sm:flex-row text-sm text-neutral-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
              >
                <span>Upload a video</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  accept="video/*"
                  className="sr-only"
                  onChange={handleVideoChange}
                />
              </label>
              {/* <p className="pl-1">or drag and drop</p> */}
            </div>
            <p className="text-xs text-neutral-500">MP4, MOV, AVI</p>

            {videoError.length > 0 && errors(videoError)}
          </div>
        </div>
      </div> }

      {selectedVideo ? (
        <div className="flex flex-col gap-1 ">
          <Label>Selected Video</Label>
          {/* Show the selected video here */}
          <video
            className="max-h-80 max-w-[80%] rounded overflow-hidden"
            src={URL.createObjectURL(selectedVideo)}
            autoPlay
            muted
            controls={true}
            controlsList="nodownload"
          ></video>

          <p className="text-sm mt-3 text-neutral-6000">{selectedVideo.name}</p>
        </div>
      ) : (
        <div></div>
      )}

      { linkError && <p className='text-red-500 col-span-2'>{linkError}</p> }

      <div className="mt-2 space-x-3">
        <ButtonPrimary type="submit" loading={isLoading}>
          Submit
        </ButtonPrimary>
      </div>

    </form>
  );
};

export default QuestionCommentForm;
