import Layout from '../layout';
import SingleHeader from 'components/UI/Single/SingleHeader';
import ForExperts from 'app/(singles)/ForExperts';

const ExpertsPageAbout = () => {
  return (
    <Layout content={<ForExperts />}>
      <header className="relative py-10 z-10 md:py-14 lg:py-20 bg-neutral-900 dark:bg-black">
        {/* SINGLE HEADER */}
        <div className="dark container relative z-10">
          <div className="max-w-screen-lg">
            <SingleHeader
              title="Are you looking to sell your expertise to the world?"
              tagName="For Experts"
              href="/experts-consultants"
              date="08/12/2024"
              readingTime="2 min read"
            />
          </div>
        </div>
      </header>
    </Layout>
  );
};

export default ExpertsPageAbout;
