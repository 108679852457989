import Image from 'components/Image';
import featured_img from '../../images/orakly-links.png';

const TrainersAboutContent = () => {
  return (
    <>
      <h2>For Trainers/Educators - Selling Courses, Training & Education</h2>
      <p>
        Are you an e-learning provider, training business or higher educational
        establishment, Are you looking for a better way to engage with people
        looking for your services?
      </p>
      <blockquote>
        <p>Driving the trust in expert advice, one video at a time!</p>
      </blockquote>
      <p>
        Orakly is a video-based expertise marketplace, where you answer
        questions from your potential market, build an OraklyScore<sup>TM</sup>{' '}
        and use the answers to sell your products and services.
      </p>
      <p>For those of you with questions, the process is really simple:</p>
      <ol>
        <li>
          Create an account - see ‘
          <a href="/signup" target="_blank" rel="noopener noreferrer">
            Get Started
          </a>
          ’
        </li>
        <li>
          Find questions from users looking for answers in your sector
          <ol type="a">
            <li>Search for questions (see the search bar)</li>
            <li>Browse our categories</li>
          </ol>
        </li>
        <li>Answer questions, with a video - you get more points for that</li>
        <li>
          Add links to your answers, leading users to your website, courses or
          social media platforms to sell your products and services
        </li>
        <li>Create a new sales channel and raise your online marketing game</li>
      </ol>
      <Image
        src={featured_img}
        alt="nc blog"
        sizes="(max-width: 1024px) 100vw, 1240px"
        className="rounded-2xl"
        width={1635}
        height={774}
      />
    </>
  );
};

export default TrainersAboutContent;
