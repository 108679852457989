import React from 'react';
import logoImg from '../../assets/images/main-logo.png';
import logoLightImg from 'images/logo-light.png';
import Link from 'components/Link';

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
}) => {
  return (
    <Link
      href="/"
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
    >
      <img src={img} className="h-10" alt="logo" />
    </Link>
  );
};

export default Logo;
