import React, { ChangeEvent, FC, useState } from 'react';

import Avatar from 'components/Avatar/Avatar';
import { CurrentUserType, QuestionDataType } from 'data/types';

import Link from 'components/Link';
import { getBusinessQuestions, updateQuestionVisibility } from 'store/question';
import { useDispatch } from 'react-redux';
import axiosInstance from 'services/axiosInstance';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Select from 'components/Select/Select';
import ArchiveFilterListBox from 'components/ArchiveFilterListBox/ArchiveFilterListBox';
import SelectItems from 'components/ArchiveFilterListBox/SelectItems';

export interface QuestionMeta2Props {
  className?: string;
  author: CurrentUserType;
  date: Date;
  hiddenCategories?: boolean;
  size?: 'large' | 'normal';
  avatarRounded?: string;
  question: QuestionDataType
}

const QuestionMeta2: FC<QuestionMeta2Props> = ({
  className = 'leading-none',
  author,
  date,
  hiddenCategories = false,
  size = 'normal',
  avatarRounded,
  question
}) => {

  const dispatch = useDispatch()

  const { currentUser } = useSelector((state: RootState) => state.auth);

  const [visibility, setVisibility] = useState<{ name: string, value: string }>({ name: question.visibility, value: question.visibility })

  const handleChange = async (value: { name: string; value: string; }) => {
    setVisibility({ name: value.name, value: value.value })
    const response = await axiosInstance({
      url: '/api/questions/visibility/' + question.id,
      method: 'PATCH',
      body: { visibility: value.value }
    })
    window.location.reload();
    // dispatch(updateQuestionVisibility(question.id, e.target.value))
  }

  const OPTIONS = [
    { name: 'Public', value: 'public' },
    { name: 'Private', value: 'private' }
  ]

  return (
    <div className='flex flex-col'>
    <div
      className={`nc-QuestionMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 ${
        size === 'normal' ? 'text-xs' : 'text-sm'
      } ${className}`}
    >
      <Link
        href={`/profile/${author.id}`}
        className="flex items-center space-x-2"
      >
        <Avatar
          radius={avatarRounded}
          sizeClass={
            size === 'normal'
              ? 'h-6 w-6 text-sm'
              : 'h-10 w-10 sm:h-11 sm:w-11 text-xl'
          }
          imgUrl={author.avatarUrl}
          userName={author.firstName + ' ' + author.lastName}
        />
      </Link>
      <div className="ml-3">
        <div className="flex items-center">
          <Link href={`/profile/${author.id}`} className="block font-semibold">
            {`${author.firstName} ${author.lastName ? author.lastName : ''}`}
          </Link>

          {/* {!hiddenCategories && (
            <>
              <span className="mx-2 font-semibold">·</span>
              <div className="ml-0">
                <span className="text-xs">🏷 </span>
                {categories.map((cat, index) => (
                  <Link
                    key={cat.id}
                    href={`/categories/${cat.id}`}
                    className="font-semibold"
                  >
                    {cat.title}
                    {index < categories.length - 1 && <span>, </span>}
                  </Link>
                ))}
              </div>
            </>
          )} */}
        </div>
        <div className="text-xs mt-[6px]">
          <span className="text-neutral-700 dark:text-neutral-300">
            {new Date(date).toLocaleDateString('en-UK', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </span>
        </div>
      </div>
    </div>
      { (question.business && question.business === currentUser?.id) && (
        <SelectItems
          lists={OPTIONS}
          selected={visibility}
          onChange={handleChange}
          className='mt-4'
        />
        // <Select className='mt-4' value={visibility} onChange={handleChange} >
        //   <option value='public'>Public</option>
        //   <option value='private'>Private</option>
        // </Select>
      ) }
    </div>
  );
};

export default QuestionMeta2;
