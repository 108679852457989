export const regions = [
    { id: 1, name: 'Africa' },
    { id: 2, name: 'Asia' },
    { id: 3, name: 'Austrailia' },
    { id: 4, name: 'Central America' },
    { id: 5, name: 'Europe' },
    { id: 6, name: 'Middle East' },
    { id: 7, name: 'North America' },
    { id: 8, name: 'Pacific' },
    { id: 9, name: 'South America' }
]