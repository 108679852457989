import React, { FC, useState, useEffect } from 'react';
import convertNumbThousand from 'utils/convertNumbThousand';
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/20/solid';
import { RootState, useAppDispatch as useDispatch } from 'store/store';
import { useParams } from 'react-router-dom';
import {
  downvoteAnswer,
  downvoteQuestion,
  upvoteAnswer,
  upvoteQuestion,
} from 'store/question';
import { useSelector } from 'react-redux';

export interface PostCardLikeActionProps {
  className?: string;
  id?: string;
  upVotes?: string[];
  downVotes?: string[];
  isQuestion: boolean;
  query?: string;
  filterBy?: string;
  isSearchPage?: boolean;
}

const PostCardLikeAction: FC<PostCardLikeActionProps> = ({
  className = 'px-3 h-8 text-xs',
  id,
  upVotes,
  downVotes,
  isQuestion,
  query,
  filterBy,
  isSearchPage,
}) => {
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const userID = currentUser?.id;
  const [isLiked, setisLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const dispatch = useDispatch();
  const { id: questionId } = useParams();

  useEffect(() => {
    if (userID && upVotes?.includes(userID)) {
      setisLiked(true);
      setIsDisliked(false);
    } else if (userID && downVotes?.includes(userID)) {
      setIsDisliked(true);
      setisLiked(false);
    }
  }, [userID, downVotes, upVotes]);

  const onUpvote = async () => {
    if (id) {
      setisLiked(!isLiked);
      setIsDisliked(false);
      if (isQuestion) {
        await dispatch(
          upvoteQuestion({
            id,
            query: query || '',
            filterBy: filterBy || 'RECENT',
            isSearchPage: isSearchPage || false,
          })
        );
      } else {
        await dispatch(upvoteAnswer({ id, questionId: id }));
      }
    }
  };

  const onDownvote = async () => {
    if (id) {
      setIsDisliked(!isDisliked);
      setisLiked(false);
      if (isQuestion) {
        await dispatch(
          downvoteQuestion({
            id,
            query: query || '',
            filterBy: filterBy || 'RECENT',
            isSearchPage: isSearchPage || false,
          })
        );
      } else {
        await dispatch(downvoteAnswer({ id, questionId: id }));
      }
    }
  };

  return (
    <>
      <button
        className={`nc-PostCardLikeAction relative min-w-[68px] flex items-center justify-center rounded-full leading-none group transition-colors ${className} ${
          isLiked
            ? 'text-teal-600 bg-teal-50 dark:bg-teal-100'
            : 'text-neutral-700 bg-neutral-50 dark:text-neutral-200 dark:bg-neutral-800 hover:bg-teal-50 dark:hover:bg-teal-100 hover:text-teal-600 dark:hover:text-teal-500'
        }`}
        onClick={onUpvote}
        title="Liked"
      >
        <HandThumbUpIcon className={`w-4 h-4 mr-1`} />

        {upVotes && (
          <span
            className={`ml-1 ${
              isLiked
                ? 'text-teal-600'
                : 'text-neutral-900 dark:text-neutral-200'
            }`}
          >
            {convertNumbThousand(upVotes.length || 0)}
          </span>
        )}
      </button>
      <button
        className={`nc-PostCardLikeAction relative min-w-[68px] flex items-center justify-center rounded-full leading-none group transition-colors ${className} ${
          isDisliked
            ? 'text-rose-600 bg-rose-50 dark:bg-rose-100'
            : 'text-neutral-700 bg-neutral-50 dark:text-neutral-200 dark:bg-neutral-800 hover:bg-rose-50 dark:hover:bg-rose-100 hover:text-rose-600 dark:hover:text-rose-500'
        }`}
        onClick={onDownvote}
        title="Liked"
      >
        <HandThumbDownIcon className={`w-4 h-4 mr-1`} />

        {downVotes && (
          <span
            className={`ml-1 ${
              isDisliked
                ? 'text-rose-600'
                : 'text-neutral-900 dark:text-neutral-200'
            }`}
          >
            {convertNumbThousand(downVotes.length || 0)}
          </span>
        )}
      </button>
    </>
  );
};

export default PostCardLikeAction;
