import Image from 'components/Image';
import featured_img from '../../images/orakly-linkedIn.png';

const ExpertsAboutContent = () => {
  return (
    <>
      <h2>For Experts/Consultants - Selling Your Expertise</h2>
      <p>
        Are you looking for a better way to build a profile as a subject-matter
        expert, engage directly with potential customers and sell your
        expertise? Great, welcome to Orakly, the trusted, video Q&A-based
        expertise marketplace!
      </p>
      <blockquote>
        <p>Driving the trust in expert advice, one video at a time!</p>
      </blockquote>
      <p>
        Orakly is a video-based expertise marketplace, where experts and
        consultants can answer questions, build an OraklyScore<sup>TM</sup> and
        use that to prove their knowledge to the world.
      </p>
      <p>
        For those of you looking to be seen as a subject-matter expert, the
        process is really simple:
      </p>
      <ol>
        <li>
          Create an account - see ‘
          <a href="/signup" target="_blank" rel="noopener noreferrer">
            Get Started
          </a>
          ’
        </li>
        <li>
          Find questions from users looking for answers in your sector
          <ol type="a">
            <li>Search for questions (see the search bar)</li>
            <li>Browse our categories</li>
          </ol>
        </li>
        <li>Answer questions, with a video - you get more points for that</li>
        <li>
          See your OraklyScore<sup>TM</sup> grow, become a ‘Category Captain’
          and showcase your profile on your social media platforms
        </li>
      </ol>
      <Image
        src={featured_img}
        alt="nc blog"
        sizes="(max-width: 1024px) 100vw, 1240px"
        className="rounded-2xl"
        width={1635}
        height={774}
      />
    </>
  );
};

export default ExpertsAboutContent;
