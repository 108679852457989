import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch as useDispatch } from 'store/store';

import Avatar from 'components/Avatar/Avatar';
import Link from 'components/Link';
import SingleCommentForm from 'app/(singles)/SingleCommentForm';
import AnswerCardLikeReply from '../AnswerCardLikeReply/AnswerCardLikeReply';
import { CurrentUserType, AnswerDataType } from 'data/types';
import { upvoteAnswer } from 'store/question';
import Button from 'components/Button/Button';
import { getUser } from 'store/auth';
import { useSelector } from 'react-redux';

export interface AnswerType {
  id: string;
  date: string;
  content: string;
  author: CurrentUserType;
  question?: string;
  like: {
    count: number;
    isLiked: boolean;
  };
  user: CurrentUserType | string;
}

export interface AnswerCard2Props {
  className?: string;
  answer: AnswerDataType;
  size?: 'large' | 'normal';
  avatarUrl?: string;
}

const AnswerCard2: FC<AnswerCard2Props> = ({
  className = '',
  answer,
  size = 'large',
  avatarUrl,
}) => {
  const { id } = useParams<{ id: string }>();
  const { createdAt, body, link, upVotes, user, video, status, question } =
    answer;
  const isLiked = upVotes.includes(id!);

  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.auth.user);
  const handleLike = (val: boolean) => {
    dispatch(upvoteAnswer({ questionId: id!, id: answer.id }));
    if (typeof user == 'string') dispatch(getUser(user));
  };

  const textareaRef = useRef(null);
  const [isReplying, setIsReplying] = useState(false);

  const closeReplyForm = () => {
    setIsReplying(false);
  };

  const renderAnswerForm = () => {
    return (
      <SingleCommentForm
        textareaRef={textareaRef}
        onClickSubmit={closeReplyForm}
        onClickCancel={closeReplyForm}
        className="flex-grow"
      />
    );
  };

  return (
    <>
      <div className={`nc-AnswerCard2 flex ${className}`} key={answer?.id}>
        <Avatar
          sizeClass={`h-6 w-6 text-base ${
            size === 'large' ? 'sm:text-lg sm:h-8 sm:w-8' : ''
          }`}
          radius="rounded-full"
          containerClassName="mt-4"
          imgUrl={answer?.user?.avatarUrl || avatarUrl || ''}
        />
        <div className="flex-grow flex flex-col h-full p-4 ml-2 text-sm border border-neutral-200 rounded-xl sm:ml-3 sm:text-base dark:border-neutral-700">
          {/* AUTHOR */}
          <div className="relative flex items-center pr-6">
            <Link
              className="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100"
              href={`/profile/${user.id || userData.id}`}
            >
              {user.firstName || userData.firstName}{' '}
              {user.lastName || userData.lastName}
            </Link>
            <span className="mx-2">·</span>
            <span className="text-neutral-500 dark:text-neutral-400 text-xs line-clamp-1 sm:text-sm">
              {new Date(createdAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </span>
          </div>

          {/* CONTENT */}
          <span className="block text-neutral-700 mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-neutral-300 break-all">
            {body.length <= 100 ? body : body.slice(0, 100) + '...'}
          </span>

          {video && status === 'approved' && (
            <div className="flex flex-col justify-start mb-4">
              <video
                className="max-h-40 w-full rounded overflow-hidden mt-2"
                src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/${video}`}
                autoPlay
                muted
                controls={true}
                controlsList="nodownload"
              ></video>
            </div>
          )}

          {/* ACTION LIKE REPLY */}
          {isReplying ? (
            renderAnswerForm()
          ) : (
            <div className="flex justify-between justify-self-end mt-auto">
              <AnswerCardLikeReply
                className={className}
                isLiked={isLiked}
                likeCount={upVotes.length}
                onClickReply={() => setIsReplying(true)}
                onClickLike={handleLike}
              />
              {question && (
                <Button
                  sizeClass="py-1 px-3"
                  href={`/questions/${question}`}
                  pattern="custom"
                  className="gap-2 sm:text-sm"
                >
                  View
                </Button>
              )}
            </div>
          )}
        </div>
      </div>

      {/* <ModalEditAnswer
        show={isEditting}
        onCloseModalEditAnswer={closeModalEditAnswer}
      />
      <ModalReportItem
        show={isReporting}
        onCloseModalReportItem={closeModalReportAnswer}
      />
      <ModalDeleteAnswer
        show={isDeleting}
        onCloseModalDeleteAnswer={closeModalDeleteAnswer}
      /> */}
    </>
  );
};

export default AnswerCard2;
