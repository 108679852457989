import { createSlice } from '@reduxjs/toolkit';
import { AnswerDataType, QuestionDataType } from 'data/types';
import axiosInstance from '../services/axiosInstance';
import catchErrors from '../services/catchErrors';
import { AppDispatch as Dispatch } from './store';
import { RootState } from './store';

const initialState = {
  questions: [] as QuestionDataType[],
  filteredQuestions: [] as QuestionDataType[],
  featuredQuestions: [] as QuestionDataType[],
  userQuestions: [] as QuestionDataType[],
  businessQuestions: [] as QuestionDataType[],
  categoryQuestions: [] as QuestionDataType[],
  myVideos: [] as AnswerDataType[],
  question: null,
  isLoading: false,
  errors: [],
};

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    clearQuestions: (state) => {
      state.questions = [];
    },
    setFilteredQuestions: (state, action) => {
      state.filteredQuestions = action.payload;
    },
    setMyVideos: (state, action) => {
      state.myVideos = action.payload;
    },
    clearFilteredQuestions: (state) => {
      state.userQuestions = [];
    },
    setUserQuestions: (state, action) => {
      state.userQuestions = action.payload;
    },
    setBusinessQuestions: (state, action) => {
      state.businessQuestions = action.payload;
    },
    clearUserQuestions: (state) => {
      state.userQuestions = [];
    },
    setCategoryQuestions: (state, action) => {
      state.categoryQuestions = action.payload;
    },
    clearCategoryQuestions: (state) => {
      state.categoryQuestions = [];
    },
    filterQuestions: (state, action) => {
      if (action.payload === 'all') {
        state.filteredQuestions = state.questions;
      } else {
        state.filteredQuestions = state.questions.filter(
          (question) => question.category === action.payload
        );
      }
    },
    setFeaturedQuestions: (state, action) => {
      state.featuredQuestions = action.payload;
    },
    clearFeaturedQuestions: (state) => {
      state.featuredQuestions = [];
    },
    setQuestion: (state, action) => {
      state.question = action.payload;
    },
    clearQuestion: (state) => {
      state.question = null;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clearErrors: (state) => {
      state.errors = [];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    sortByNewest: (state) => {
      state.filteredQuestions = state.filteredQuestions.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          return 0;
        }
        return dateB.getTime() - dateA.getTime();
      });
    },
    sortByOldest: (state) => {
      state.filteredQuestions = state.filteredQuestions.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          return 0;
        }
        return dateA.getTime() - dateB.getTime();
      });
    },
    sortByMostPopular: (state) => {
      state.filteredQuestions = state.filteredQuestions.sort((a, b) => {
        return b.upVotes.length - a.upVotes.length;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setQuestions,
  clearQuestions,
  setFilteredQuestions,
  setMyVideos,
  clearFilteredQuestions,
  setUserQuestions,
  setBusinessQuestions,
  clearUserQuestions,
  setCategoryQuestions,
  clearCategoryQuestions,
  filterQuestions,
  setFeaturedQuestions,
  clearFeaturedQuestions,
  setQuestion,
  clearQuestion,
  setErrors,
  clearErrors,
  setLoading,
  sortByNewest,
  sortByOldest,
  sortByMostPopular,
} = questionSlice.actions;

// --------------------------------------------------------

export const getQuestions =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: '/api/questions',
        method: 'GET',
      });

      dispatch(setQuestions(response.data.questions));
      dispatch(setFilteredQuestions(response.data.questions));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting questions: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getUserQuestions =
  ({ id }: { id: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: '/api/questions/user/' + id,
        method: 'GET',
      });

      dispatch(setUserQuestions(response.data.questions));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting questions: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getBusinessQuestions = ({ id }: { id: string }) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: '/api/questions/business/' + id,
        method: 'GET',
      });

      dispatch(setBusinessQuestions(response.data.questions));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting business questions: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getCategoryQuestions =
  ({ id }: { id: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: '/api/questions/category/' + id,
        method: 'GET',
      });

      dispatch(setCategoryQuestions(response.data.questions));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting category questions: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------


export const updateQuestionVisibility = ({ id, visibility }: { id: string, visibility: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: '/api/questions/visibility/' + id,
        method: 'PATCH',
        body: { visibility }
      });

      // dispatch(setCategoryQuestions(response.data.questions));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while updating question visibility: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getQuestion = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axiosInstance({
      url: `/api/questions/${id}`,
      method: 'GET',
    });

    dispatch(setQuestion(response.data.question));
    dispatch(setLoading(false));
    return true;
  } catch (err) {
    console.log(`Error while getting question: ${err}`);
    const errs = catchErrors(err);
    dispatch(setErrors(errs));
    dispatch(setLoading(false));
    return false;
  }
};

// --------------------------------------------------------

export const createQuestion =
  ({
    title,
    body,
    categories,
    status,
    businessId
  }: {
    title: string;
    body: string;
    categories: string[];
    status: string;
    businessId?: string
  }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().auth.currentUser;
    const token = currentUser ? currentUser.token : null;

    console.log(`Creating question with categories: ${categories}`);

    try {
      dispatch(setLoading(true));
      await axiosInstance({
        url: '/api/questions',
        method: 'POST',
        body: {
          title,
          body,
          categories,
          status,
          businessId
        },
        token: token,
      });

      await dispatch(getQuestions());
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while creating question: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const updateQuestion =
  ({
    id,
    title,
    body,
    categories,
    video,
    businessId
  }: {
    id: string;
    title: string;
    body: string;
    categories: string[];
    video: string;
    businessId?: string;
  }) =>
  async (dispatch: Dispatch) => {
    console.log(`Updating question with categories: ${categories}`);

    try {
      dispatch(setLoading(true));
      await axiosInstance({
        url: '/api/questions',
        method: 'PUT',
        body: {
          id,
          title,
          body,
          categories,
          video,
          businessId
        },
      });

      dispatch(getQuestions());
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while updating question: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const deleteQuestion = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));
    await axiosInstance({
      url: '/api/questions',
      method: 'DELETE',
      body: { id },
    });

    await dispatch(getQuestions());
    dispatch(setLoading(false));
    return true;
  } catch (err) {
    console.log(`Error while updating question: ${err}`);
    const errs = catchErrors(err);
    dispatch(setErrors(errs));
    dispatch(setLoading(false));
    return false;
  }
};

// --------------------------------------------------------

export const getUploadURL =
  ({
    questionId,
    filename,
    fileType,
  }: {
    questionId: string;
    filename: string;
    fileType: string;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/answers/upload?questionId=${questionId}&filename=${filename}&fileType=${fileType}`,
        method: 'GET',
      });

      return response.data.url;
    } catch (err) {
      console.log(`Error while getting upload URL: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------


export const getUploadVideoURL =
  ({
    filename,
    fileType,
  }: {
    filename: string;
    fileType: string;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/answers/upload-video?filename=${filename}&fileType=${fileType}`,
        method: 'GET',
      });

      return response.data.url;
    } catch (err) {
      console.log(`Error while getting upload URL: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getQuestionUploadURL =
  ({
    title,
    body,
    filename,
    fileType,
  }: {
    title: string;
    body: string;
    filename: string;
    fileType: string;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/questions/upload?title=${title.toString()}&body=${body.toString()}&filename=${filename}&fileType=${fileType}`,
        method: 'GET',
      });

      return { url: response.data.url, questionId: response.data.questionId };
    } catch (err) {
      console.log(`Error while getting upload URL: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return { url: '', questionId: '' };
    }
  };

// --------------------------------------------------------

export const addAnswer =
  ({ id, answer, video, link, preRecordedVideo }: { id: string; answer: string; video?: string, link?: string, preRecordedVideo?: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().auth.currentUser;
    const token = currentUser ? currentUser.token : null;
    try {
      dispatch(setLoading(true));
      await axiosInstance({
        url: '/api/answers',
        method: 'POST',
        body: {
          questionId: id,
          body: answer,
          video: video,
          link,
          preRecordedVideo
        },
        token,
      });

      await dispatch(getQuestion(id));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while creating answer: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------


export const uploadAnswer =
  ({ title, body, video }: { title: string; body: string; video: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().auth.currentUser;
    const token = currentUser ? currentUser.token : null;
    try {
      dispatch(setLoading(true));
      await axiosInstance({
        url: '/api/answers/upload-video',
        method: 'POST',
        body: {
          title,
          body,
          video: video
        },
        token,
      });

      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while creating answer: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------


export const getMyVideos = (id: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/answers/${id}/my-videos`,
        method: 'GET',
      });

      dispatch(setMyVideos(response.data.answers));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting search results: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getAllSearchResults =
  ({
    query,
    category,
    filterBy,
  }: {
    query: string;
    category: string | null;
    filterBy: string;
  }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/questions/search/all?query=${query}&category=${
          category ? category : 'all'
        }&filterBy=${filterBy}`,
        method: 'GET',
      });
      dispatch(setFilteredQuestions(response.data.questions));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting search results: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getSearchResults =
  ({ query }: { query: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/questions/search?query=${query}`,
        method: 'GET',
      });

      dispatch(setFilteredQuestions(response.data.questions));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting search results: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getFeaturedQuestions =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/questions/featured`,
        method: 'GET',
      });

      dispatch(setFeaturedQuestions(response.data.questions));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while getting search results: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const upvoteQuestion =
  ({
    id,
    query,
    category,
    filterBy,
    isSearchPage,
  }: {
    id: string;
    query: string;
    category?: string;
    filterBy: string;
    isSearchPage: boolean;
  }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().auth.currentUser;
    const token = currentUser ? currentUser.token : null;

    try {
      dispatch(setLoading(true));
      await axiosInstance({
        url: `/api/questions/upvote`,
        method: 'POST',
        body: {
          id,
        },
        token,
      });

      if (isSearchPage) {
        await dispatch(
          getAllSearchResults({ query, category: category || 'all', filterBy })
        );
      } else {
        await dispatch(getSearchResults({ query }));
        await dispatch(getQuestion(id));
      }
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while upvoting question: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };
// --------------------------------------------------------

export const upvoteAnswer =
  ({ id, questionId }: { id: string; questionId: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().auth.currentUser;
    const token = currentUser ? currentUser.token : null;

    try {
      dispatch(setLoading(true));
      await axiosInstance({
        url: `/api/answers/upvote`,
        method: 'POST',
        body: {
          id,
        },
        token,
      });

      await dispatch(getQuestion(questionId));
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while upvoting answer: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };
// --------------------------------------------------------

export const downvoteQuestion =
  ({
    id,
    query,
    category,
    filterBy,
    isSearchPage,
  }: {
    id: string;
    query: string;
    category?: string;
    filterBy: string;
    isSearchPage: boolean;
  }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().auth.currentUser;
    const token = currentUser ? currentUser.token : null;

    try {
      dispatch(setLoading(true));
      await axiosInstance({
        url: `/api/questions/downvote`,
        method: 'POST',
        body: {
          id,
        },
        token,
      });

      if (isSearchPage) {
        await dispatch(
          getAllSearchResults({ query, category: category || 'all', filterBy })
        );
      } else {
        await dispatch(getSearchResults({ query }));
        await dispatch(getQuestion(id));
      }
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while downvoting question: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };
// --------------------------------------------------------

export const downvoteAnswer =
  ({ id, questionId }: { id: string; questionId: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().auth.currentUser;
    const token = currentUser ? currentUser.token : null;

    try {
      dispatch(setLoading(true));
      await axiosInstance({
        url: `/api/answers/downvote`,
        method: 'POST',
        body: {
          id,
        },
        token,
      });

      await dispatch(getQuestion(questionId));
      await dispatch(getQuestions());
      dispatch(setLoading(false));
      return true;
    } catch (err) {
      console.log(`Error while downvoting answer: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getModerationResult =
  ({ questionId, answerId }: { questionId: string; answerId: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().auth.currentUser;
    const token = currentUser ? currentUser.token : null;

    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/answers/moderation/${answerId}`,
        method: 'GET',
        token,
      });

      console.log(response.data);
      dispatch(setLoading(false));
      dispatch(getQuestion(questionId));
      return true;
    } catch (err) {
      console.log(`Error while getting moderation result: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export const getQuestionModerationResult =
  ({ questionId }: { questionId: string }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const currentUser = getState().auth.currentUser;
    const token = currentUser ? currentUser.token : null;

    try {
      dispatch(setLoading(true));
      const response = await axiosInstance({
        url: `/api/questions/moderation/${questionId}`,
        method: 'GET',
        token,
      });

      console.log(response.data);
      dispatch(setLoading(false));
      dispatch(getQuestion(questionId));
      return true;
    } catch (err) {
      console.log(`Error while getting moderation result: ${err}`);
      const errs = catchErrors(err);
      dispatch(setErrors(errs));
      dispatch(setLoading(false));
      return false;
    }
  };

// --------------------------------------------------------

export default questionSlice.reducer;
