import { FC } from 'react';
import Card8 from 'components/Card8/Card8';
import Card9 from 'components/Card9/Card9';
import Heading from 'components/Heading/Heading';
import { PostDataType } from 'data/types';

export interface FeaturedBlocksSectionProps {
  posts: PostDataType[];
  heading?: string;
  desc?: string;
  className?: string;
}

const FeaturedBlocksSection: FC<FeaturedBlocksSectionProps> = ({
  posts,
  className = '',
  heading = '',
  desc,
}) => {
  return (
    <div className={`nc-FeaturedBlocksSection ${className}`}>
      <Heading desc={desc}>{heading}</Heading>

      {!posts?.length && <span>Nothing we found!</span>}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
        {posts[0] && <Card8 className="sm:col-span-2" post={posts[0]} />}
        {posts
          .filter((_, i) => i < 3 && i >= 1)
          .map((item, index) => (
            <Card9 key={index} post={item} hasActionButtons={false} />
          ))}
        {posts
          .filter((_, i) => i < 5 && i >= 3)
          .map((item, index) => (
            <Card9 key={index} post={item} hasActionButtons={false} />
          ))}
        {posts[5] && <Card8 className="sm:col-span-2" post={posts[5]} />}
      </div>
    </div>
  );
};

export default FeaturedBlocksSection;
