import React, { FC } from 'react';
import CategoriesBadgeList from 'components/CategoriesBadgeList/CategoriesBadgeList';
import QuestionTitle from './QuestionTitle';
import QuestionMeta2 from 'components/QuestionMeta2/QuestionMeta2';
import QuestionMetaAction2 from './QuestionMetaAction2';
import { CurrentUserType, CategoryDataType, QuestionDataType } from 'data/types';

export interface QuestionHeaderProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
  categories: CategoryDataType[];
  title: string;
  user: CurrentUserType;
  createdAt: Date;
  question: QuestionDataType
}

const QuestionHeader: FC<QuestionHeaderProps> = ({
  titleMainClass,
  hiddenDesc = false,
  className = '',
  categories,
  title,
  user,
  createdAt,
  question
}) => {
  return (
    <>
      <div className={`nc-QuestionHeader ${className}`}>
        <div className="space-y-5">
          <CategoriesBadgeList itemClass="!px-3" categories={categories} />
          <QuestionTitle mainClass={titleMainClass} title={title} />
          {/* {!hiddenDesc && (
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
              {description}
            </span>
          )} */}
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col sm:flex-row justify-between space-y-5 sm:space-y-0 sm:space-x-5">
            {user && (
              <QuestionMeta2
                size="large"
                className="leading-none flex-shrink-0"
                hiddenCategories
                avatarRounded="rounded-full shadow-inner"
                author={user}
                date={createdAt}
                question={question}
              />
            )}
            <QuestionMetaAction2 />
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionHeader;
