import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import React, { FC, Fragment, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import twFocusClass from "utils/twFocusClass";
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet-async";

export interface NcDropDownItem {
  id: string;
  name: string;
  icon: string;
}

export interface NcDropDownProps {
  className?: string;
  panelMenusClass?: string;
  triggerIconClass?: string;
  data: NcDropDownItem[];
  renderTrigger?: () => ReactNode;
  renderItem?: (item: NcDropDownItem) => JSX.Element;
  title?: string;
  onClick: (item: NcDropDownItem) => void;
}

const NcDropDown: FC<NcDropDownProps> = ({
  className = `h-8 w-8 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ${twFocusClass()}`,
  triggerIconClass = "h-6 w-6",
  panelMenusClass = "origin-top-right",
  title = "More",
  renderTrigger,
  renderItem,
  data,
  onClick,
}) => {

  const { id } = useParams<{ id: string }>();

  return (
    <>
    <Helmet>
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Orakly" />
    <meta property="og:description" content="Share your expertise to the world in 60 seconds..." />
    <meta property="og:url" content={`${process.env.REACT_APP_FRONTEND_URL}%2Fquestions/${id}`} />
    <meta property="og:image" content="/logo.png" />

    <meta property="twitter:title" content="Orakly" />
    <meta property="twitter:url" content={`${process.env.REACT_APP_FRONTEND_URL}%2Fquestions/${id}`} />
    <meta property="twitter:description" content="Share your expertise to the world in 60 seconds..." />
    <meta property="twitter:image" content="/logo.png" />
    </Helmet>
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className={className} title={title}>
        {renderTrigger ? (
          renderTrigger()
        ) : (
          <EllipsisHorizontalIcon className={triggerIconClass} />
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${panelMenusClass} right-0 w-56 mt-2 bg-white dark:bg-neutral-900 rounded-2xl divide-y divide-neutral-100 shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-10 focus:outline-none z-30`}
        >
          <div className="px-1 py-3 text-sm text-neutral-6000 dark:text-neutral-300">
            {data.map((item) => (
              <Menu.Item
                as={"div"}
                key={item.id}
                onClick={() => onClick(item)}
                data-menu-item-id={item.id}
              >
                {() =>
                  renderItem && typeof renderItem(item) !== "undefined" ? (
                    renderItem(item)
                  ) : (
                    <a target="_blank" rel="noreferrer" href={item.id === 'Facebook' ? `https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_FRONTEND_URL}%2Fquestions/${id}` : item.id === 'Twitter' ? `https://twitter.com/intent/tweet?text=Check%20us%20out%20on%20Orakly:%20&url=${process.env.REACT_APP_FRONTEND_URL}%2Fquestions/${id}` : `https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_FRONTEND_URL}%2Fquestions/${id}`} className="fb-xfbml-parse-ignore">
                    <button
                      className={
                        "flex items-center rounded-xl w-full px-3 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100 truncate " +
                        twFocusClass()
                      }
                      data-href={`${process.env.REACT_APP_FRONTEND_URL}%2Fquestions/${id}`}
                    >
                      {!!item.icon && (
                        <div
                          dangerouslySetInnerHTML={{ __html: item.icon }}
                        ></div>
                        // <i className={`${item.icon} mr-1 w-7 text-base`}></i>
                      )}
                      <span className="ml-3">{item.name}</span>
                    </button>
                    </a>
                  )
                }
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    </>
  );
};

export default NcDropDown;
